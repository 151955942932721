import React, {useState} from 'react';

import { withStyles, makeStyles,} from '@material-ui/core/styles';
import {Typography, ClickAwayListener, Paper, Box, InputAdornment, Avatar, TextField, Popper, Fade, List, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import StaticURL from '../../hoc/config';

const SearchBar = () => {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [inputFocus, setInputFocus] = useState(false);
    let timerCnst = null;
    const [searchList, setSearchList] = useState([
        {
            'id':1,
            'title':"sony pictures",
            'featured_image':[{thumbnail:`${StaticURL}/wp-content/uploads/2021/08/shot6-150x150.png`}
            ]
        }
    ]);
    const id = open ? 'faked-reference-popper' : undefined;
    let currentTrigerTime = null;
    const classes =useStyles();

    const handleClose = () => {
        setOpen(false);
      };
    const onKeyD = (event) =>{
       // console.log(event);
        if(event.code === 13){
            return;
        }
    }
    const checkSearch = (event) => {
        //console.log(event);
        const timeDiff = event.timeStamp - currentTrigerTime;
        //console.log(timeDiff);
        if((timeDiff)<700){
            cancelTimeout();
            return;
        }
        currentTrigerTime = event.timeStamp;
        timerCnst = setTimeout(()=> {
            getSearchList(event.target.value);
            setAnchorEl(event.target);
        }, 500)
        
    }
    const cancelTimeout = () =>{
        clearTimeout(timerCnst);
        currentTrigerTime = null;
    }
    const getSearchPage = (e) =>{
        e.preventDefault();
        cancelTimeout();
        console.log("getSea-rchPage ", e.target[0].value);
        window.location.href = `/search?s=${e.target[0].value}`
    }

    const getSearchList = async(pValue) => {
        console.log('getSearch ',pValue, `${StaticURL}/wp-json/wl/v1/posts/?search=${pValue}`);
        currentTrigerTime = null;
        clearTimeout(timerCnst);
        if(pValue === ""){
            setSearchList([]);
            handleClose();
            return
        };
        const searchDetails = await fetch(`${StaticURL}/wp-json/wl/v1/posts/?search=${pValue}`)
        .then((data) => data.json())
        .catch(error => console.log(error))
        
        console.log(searchDetails);

        if(searchDetails !== undefined && searchDetails.information !== undefined){
            const serachArr = [...searchDetails.information];
            //console.log(serachArr);
            setSearchList(serachArr);
            setOpen(true);
        }
    }
    
    const changeURL = (pSlug) => {
        handleClose();
       window.location.href = `/posts/${pSlug}`;
    }
    
    const searchAvatar = () => {
        if(inputFocus){
            return setInputFocus(false);
        }
        return setInputFocus(true);
    }


    return (
        <React.Fragment>
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleClose}
            >
            <Box>
                <form className={classes.margin} noValidate autoComplete="off" onSubmit={(e) => {getSearchPage(e)}}>
                    <CssTextField style={{width:300}} placeholder="Search Projects" id="custom-css-standard-input" 
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon className={(inputFocus)? "searchOver" : "" } />
                                </InputAdornment>
                            ),
                        }} onChange={(e) => checkSearch(e)} onKeyUp={(e) => onKeyD(e)} onFocus={searchAvatar} onBlur={searchAvatar}/>
                </form>
                <Popper id={id} open={open} anchorEl={anchorEl} className={classes.popper} transition placement="bottom-start">
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper>
                            <List>
                                
                                {searchList.map((item) => {
                                    return (<ListItem key={item.id} style={{paddingLeft:8, paddingRight:8, paddingTop:4, paddingBottom:4}} button onClick={() => changeURL(item.slug)}>
                                        <ListItemAvatar>
                                            <Avatar alt={item.title} className={classes.small} src={item.featured_image['thumbnail']}/>
                                        </ListItemAvatar>
                                        <ListItemText>
                                        <Typography id="project-title" varient="body2" className={[classes.listText].join(" ")} component="span">{item.title}</Typography>
                                        </ListItemText>
                                    </ListItem>)
                                })
                                }
                            </List>
                        </Paper>
                    </Fade>
                    )}
                </Popper>
            </Box>
            </ClickAwayListener>
        </React.Fragment>
    )


}


const CssTextField = withStyles((theme) =>({
    root: {
        '& .MuiInputBase-input':{
            fontFamily:"URWGeometric-Regular",
            fontSize : 16, 
            color : theme.palette.textPrimary.default,
        },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#a70237',
      },
    },
  }))(TextField);
  



const useStyles = makeStyles((theme) =>({
    
    margin: {
        margin: theme.spacing(1),
    },
    popper:{
        left:"-30px !important",
        zIndex:9999,
        width:"300px",
    },
    backgroundColor:{
        backgroundColor:theme.palette.backgroundColor,
    },
    listText:{
        fontFamily:"URWGeometric-Regular !important",
        fontSize:"14px !important",
        color:theme.palette.textPrimary.default
    },
    small:{
        width: theme.spacing(3),
        height: theme.spacing(3),
    }
}))


export default SearchBar;
