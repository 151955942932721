import React, { Suspense, useState} from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
//import { connect } from 'react-redux';
import { createTheme } from '@material-ui/core/styles';

import './App.css';

import Header from './container/header/Header';
import Home from './container/Home/Home';
import PortfolioPage from './container/PortfolioPage/PortfolioPage';
import Footer from './container/Footer/Footer';
//import Portfolio from './container/Portfolio/Portfolio';


const navFont = createTheme({
  kelvinBold: {
    fontFamily:'KelvinSlab-Bold',
  },
  textFontSize:{
    fontSize: 14,
  }
});


const About = React.lazy(() => {
  return import('./container/About/About');
});

const Portfolio = React.lazy(() => {
  return import('./container/Portfolio/Portfolio');
});

const Resume = React.lazy(() => {
  return import('./container/Resume/Resume');
})

const SearchPage = React.lazy(() => {
    return import('./container/SearchPage/SearchPage');
})



const App = props => {
  const [theme, setTheme] = useState("light");
  console.log("aaaa ", navigator.userAgent)
  //const [posts, setPosts] = useState([]);
  /*useEffect(() =>{
    async function pots (){
      const response = await fetch('http://localhost/carlos/wp-json/wp/v2/pages')
      if(!response.ok){
        return;
      }    
      const posts = await response.json();
      console.log(posts);
      setPosts(posts);
    }
    pots();
  }, []);*/

  const setThemeModeHandler=(stheme) =>{
    setTheme(stheme);
  }

  let routes = (
    <Switch>
      <Route path="/about" exact render={(props) => <About {...props} theme={theme}/>} />
      <Route path="/mywork/:name" render={(props) => <Portfolio {...props} theme={theme}/>} />
      <Route path="/resume" exact render={(props) => <Resume {...props}/>} />
      <Route path="/search/" render={(props) => <SearchPage {...props} />} />
      
      <Route path="/posts/:name">
        <PortfolioPage {...props}/>
      </Route>
      <Route exact path="/" component={Home} />
      <Redirect to="/" />
    </Switch>
  );
  return (
    <div>
      <Header navFont={navFont} setThemeModeHandler={setThemeModeHandler} />
          <Suspense fallback={<p>Loading... </p>}>{routes}</Suspense>
      <Footer footerFont={navFont}/>
    </div>
  );
}

export default withRouter(App);
