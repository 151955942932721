import React, {useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {CustomThemeContext} from '../../theme/CustomThemeProvider';

import { Grid, Container, Typography, IconButton} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faGithub, faGooglePlusG } from '@fortawesome/free-brands-svg-icons'



//import CssBaseline from '@material-ui/core/CssBaseline';

import Link from '@material-ui/core/Link';

import {} from './Footer.css';

library.add(
    fab,
    faGithub,
    faGooglePlusG
  );

const Footer = props => {
    const { currentTheme } = useContext(CustomThemeContext)
    const classes = useStyles(props);

    const nameWhites = `${window.location.origin}/assets/Logos/Name-light.svg`;
    const nameDark = `${window.location.origin}/assets/Logos/Name-dark.svg`;

    const logoWhite = `${window.location.origin}/assets/Logos/logowhite.png`;
    const logoDark = `${window.location.origin}assets/Logos/footerLogoDark.svg`;



//console.log(currentTheme);

    return(
        <Container component="footer" className={["footerContainer", classes.backgroundColor].join(" ")}>
            <div style={{flexGrow:1}}>
                <Grid varient='div' container className={"linkFooter"} >
                    <Grid item xs={6} lg={6}>
                        <img src={currentTheme === "light"?nameWhites:nameDark} alt={"carlossosa"} />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <ul id='footerUl'  className={["footerUl", "textFormat"].join(" ")}>
                            <li key={Math.random()}>
                                <Link to="/mywork/all" component={RouterLink} varient="body2" underline="none" className={[classes.linkColor, "textFormat"].join(" ")} >WORK</Link>
                            </li>
                            <li key={Math.random()}>
                                <Link to="/about" component={RouterLink} varient="body2" underline="none" className={[classes.linkColor, "textFormat"].join(" ")} >ABOUT</Link>
                            </li>
                            <li key={Math.random()}>
                                <Link to="/#contact" component={RouterLink} varient="body2" underline="none" className={[classes.linkColor, "textFormat"].join(" ")} >CONTACT</Link>
                            </li>
                            <li key={Math.random()}>
                                <Link to="/resume" component={RouterLink} varient="body2" underline="none" className={[classes.linkColor, "textFormat"].join(" ")} >RESUME</Link>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <ul className={"iconUl"}>
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><FacebookIcon fontSize="small" /></IconButton></li>
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><InstagramIcon fontSize="small" /></IconButton></li>
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><i className="fa fontss fa-dribbble"></i></IconButton></li>
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><i className="fa fontss fa-behance"></i></IconButton></li>
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><LinkedInIcon fontSize="small" /></IconButton></li>
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><FontAwesomeIcon icon={['fab', 'google-plus-g']} style={{fontSize:"1.25rem"}} /></IconButton></li>
                        {/*<li><IconButton className={"footerIconHolder"}><i class="fab fa-google-plus-g"></i> </IconButton></li>*/}
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><GitHubIcon fontSize="small" style={{fontSize:18}} /></IconButton></li>
                        <li><IconButton className={["footerIconHolder", classes.footerIconHolder].join(" ")}><i className="fa fontss fa-spotify" style={{fontSize:20}}></i></IconButton></li>
                    </ul>
                </Grid>
                <Grid item xs={12} className={"footerCopyRight"} >
                <Typography className={classes.copyright}>@ 2021 CARLOS SOSA DESIGN </Typography>
                <img src={currentTheme === "light"?logoWhite:logoDark} alt={logoWhite} />
                    
                </Grid>
            </div>
        </Container>
    );

}

const useStyles = makeStyles((theme) =>({
        type:{type:theme.palette.type},
        margin: {
          margin: theme.spacing(1),
        },
        backgroundColor:{
            backgroundColor:theme.palette.footerBackgroundColor,
        },
        navLink:{
            //underline: none,
        },
        footerIconHolder: {
            padding:4,
            color: theme.palette.textSecondary.default,
            borderRadius: "20%"
        },
        linkColor:{
            color: theme.palette.textSecondary.default,
            fontSize: 14,
            fontFamily:"KelvinSlab-Bold",
        },
        copyright:{
            fontFamily:'URWGeometric-Regular',
            fontSize: 12
        }
    
}))

export default Footer;