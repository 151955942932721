import React, { useState, useEffect, useCallback} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, ImageList, Typography, Card, Paper, CardActions, Button} from '@material-ui/core';
//import Link from '@material-ui/core/Link';
import CardThumb from '../../components/CardThumb/CardThumb';
import {} from './WorkArea.css';

import StaticURL from '../../hoc/config';

import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


import brandingsvg from '../../assets/Images/Branding-svg.png';


const WorkArea = (props) => {
    const [colNumber, setColNumber] = useState(3);
    const [postThumb, setPostThumb] = useState([]);
    const [selectedTile, setSelectedTile] = useState(postThumb[0]);
    

    const staticURL = `${StaticURL}/wp-json/wl/v1`;
    let linkurl = window.location;


    useEffect(() => {
        window.addEventListener('resize',handleResize)
    })
    const handleResize = () => {
        if(window.innerWidth<=1024){
            setColNumber(2);
        }
        //console.log(window.innerWidth);
    }

    const fetchPost = useCallback(async() => {
        const pathArr = linkurl.pathname.split("/");
        let fetchURL = `${staticURL}/posts/`;
        
        if(pathArr[pathArr.length-1] !== 'all'){
            fetchURL = fetchURL+`?categories=${pathArr[pathArr.length-1]}`;
        }

        const fetchPostInformation = await fetch(fetchURL)
        .then(data => data.json())
        .catch((error) => console.log(error));

        if(fetchPostInformation?.information !== undefined){

            if(fetchPostInformation?.information !== ''){
               // console.log("uyyy");
                //setLoadedData(true);
                
                if(fetchPostInformation?.information['code'] === 400){
              //      setWrongData(fetchPostInformation?.information['message']);
                    return;
                }
            }
            //const dummyCont = [fetchPostInformation.information[0]];
            //console.log(fetchPostInformation.information);
            //console.log(dummyCont);
            setPostThumb(fetchPostInformation.information);
            setSelectedTile(fetchPostInformation.information[0]);
            //setPostData(fetchPostInformation.information);
           // setFilteredPostData(fetchPostInformation.information);
            
        }
        
    }, [staticURL, linkurl.pathname])
    
    useEffect(() => {
        fetchPost();
    }, [fetchPost])

    const setLargeImageDetails = (tile) => {
        setSelectedTile(tile);
    }

    const largeShareWindow = () =>{
        props.openShareWindow(selectedTile);
    }

    const openViewWindow = (pTile) => {
        window.location.href = `/posts/${pTile.slug}`;
    }
    const openCommentWindow = (pTile) => {
        window.location.href = `/posts/${pTile.slug}#comment`;
    }
    
    

    const workclasses = useStyles();
    return(
        
            <Container component="div" varient='div' className={workclasses.workArea}>
                <Grid maxwidth="lg" component="div" className="workArea" style={{paddingBottom:180}}>
                    <Box className={workclasses.headerBox}>
                        <Typography component="h3" varient="h3" className={workclasses.headerTitle}>Work</Typography>
                    </Box>
                    <Grid container spacing={0} style={{marginLeft:32}}>
                        <Grid item lg={5} sm={6} >
                            <Card className={workclasses.paperBackground} style={{paddingLeft:0, paddingRight:0}}>
                                <img id="largeImage" className={[workclasses.largeWorkMedia, "productLargeImage"].join(" ")} src={selectedTile !== undefined ? selectedTile.featured_image['large'] : ""} alt="Project"/>
                                <CardActions style={{padding:"8px 20px"}}>
                                        <WorkOutlineIcon className={workclasses.iconBg}/>
                                    <Typography id="project-title" varient="body2" className={workclasses.workThumbTitle} component="p" style={{fontSize:32, width:370}}>{selectedTile !== undefined ? selectedTile.title : ""}</Typography>
                                    <img src={brandingsvg} alt={brandingsvg} style={{width:"9%", marginLeft:'auto'}}/>
                                </CardActions>
                            </Card>
                            <Grid container spacing={0} style={{marginTop:2}}>
                                <Grid item xs={6} lg={6} sm={6}>
                                    <Paper className={workclasses.paperBackground}>
                                        <Box style={{display:'flex', justifyContent:'center'}}>
                                            <Button aria-label="Work" className={workclasses.buttons} onClick={largeShareWindow}>
                                                <ShareOutlinedIcon className={workclasses.iconButton}/>
                                            </Button> 
                                        </Box>
                                    
                                    </Paper>
                                </Grid>
                                <Grid item xs={6} sm={6} lg={6} style={{display:"flex"}}>
                                    <Grid item xs={6} sm={6} lg={6}>
                                        <Paper className={workclasses.paperBackground}>
                                        <Box style={{display:'flex', justifyContent:"space-evenly", alignItems:"center"}}>
                                            <Button to={`/posts/${selectedTile !== undefined ? selectedTile.slug : ""}#comment`} component={RouterLink} aria-label="Work" className={workclasses.buttons} style={{marginRight:"0px"}}>
                                                <CommentOutlinedIcon className={workclasses.iconButton} style={{marginRight:"5px"}}/>
                                                <Typography varient="body2" className={workclasses.workThumbTitle} style={{marginTop:-5, width:"100%", marginLeft:0, color:'#a70237', fontSize:24}} component="p">{selectedTile !== undefined ? selectedTile.total_comments : 0}</Typography>
                                            </Button>
                                            
                                        </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={6} sm={6} lg={6}>
                                        <Paper className={workclasses.paperBackground}>
                                        <Box style={{paddingTop:22, paddingBottom:22, display:'flex', justifyContent:"space-evenly", alignItems:"center"}}>
                                            <Button  to={`/posts/${selectedTile !== undefined ? selectedTile.slug : ""}`} component={RouterLink} aria-label="Work" className={workclasses.icons} style={{marginRight:"0px", width:"unset"}}>
                                                <VisibilityOutlinedIcon className={workclasses.iconButton} style={{marginRight:"5px"}}/>
                                                <Typography varient="body2" className={workclasses.workThumbTitle} style={{marginTop:-5, width:"100%", marginLeft:0, color:'#a70237', fontSize:24, overflow:"hidden"}} component="p">{selectedTile !== undefined ? selectedTile.views : 0 }</Typography>
                                            </Button>
                                            
                                        </Box>
                                        </Paper>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={7} sm={6}>
                            <ImageList key={Math.random()} className={workclasses.workGridList} gap={-5} rowHeight={220} cols={colNumber}>
                            {postThumb.map((tile) => (
                                
                                <CardThumb postId={tile.id} key={tile.title} workclasses={workclasses} tile={tile} setLargeImageDetails={setLargeImageDetails} openCommentWindow={openCommentWindow} openViewWindow={openViewWindow} openShareWindow={props.openShareWindow} />
                            ))}
                                <Card className={"workGridThumb"} style={{display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"#a70237"}} >
                                        <Button className={[workclasses.verticalIcon, workclasses.viewButton].join(" ")} disableRipple disableFocusRipple component={RouterLink} to="/mywork/all" href="/mywork/all">
                                            <Typography  underline="none" varient="h3" className={"workAllText"}>View All </Typography>
                                        <KeyboardArrowRightIcon style={{color:"#FFF", fontSize:50}}/>
                                        </Button>
                                        
                                    
                                </Card>
                            </ImageList>    
                            
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
    )
}


const useStyles = makeStyles((theme) =>({
    
    paperBackground:{
        backgroundColor: theme.palette.backgroundColor
    },
    workGridList: {
        flexWrap: 'wrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      },
      workGridThumb:{
        marginLeft:8,
        marginRight:8,
        marginBottom:16,
      },
      workArea:{
        backgroundColor: theme.palette.sectionBackgroundColor,
        maxWidth:"100%",
      },
      icons:{
        backgroudColor:'#FFF',
        margin:2,
        display:'flex',
        justifyContent:"center",
        alignItems: "center",
        width:"2.5em",
        height: "2em",

      },
      buttons:{
        backgroudColor:'#FFF',
        margin:12,
        '&:hover': {
            backgroundColor: "unset",
            //color: "#FFFFFF"
        }
      },
      iconButton:{
        fontSize:'2.5rem', 
        color:'#a70237',
        '&:hover': {
            color: "#F2024E"
        }
      },
      iconBg:{
        backgroundColor:"#470015",
        fontSize:'3.5rem', color:'#FFF',
        padding:13,
        borderRadius:50
      },
      
      cardActionStyle:{
        justifyContent:'right'
      },
      favButton:{
        borderRadius:"10%", 
        background:"rgba(255, 255, 255, 0.7)",
        padding: 4,
        marginRight:10
      },
      iconFav:{
        fontSize:16, 
        color:"#a70237 !important",
        '&:hover': {
            color: "#F2024E"
        }
      },
      
      workThumbTitle:{
        fontFamily:"URWGeometric-Regular",
        fontSize: 16,
        marginLeft:5,
        textOverflow: "ellipsis;",
        width:110,
        overflow: "hidden",
        whiteSpace: "nowrap",
        color: theme.palette.textPrimary.default
      },
      viewButton:{
        padding: 0,
          '& .MuiButton-text':{
              
          }
      },
      verticalIcon:{
        '&:hover': {
            backgroundColor: "unset",
            '& $workAllText':{
                color: "#F2024E !important",
            }
        }
      },
      vertIcon:{
          color:"#a70237",
          '&:hover': {
            color:"#F2024E",
          }
      },
      largeWorkMedia:{
        width: "100%",
       // height:486,
      },
      workMedia:{
          width:212,
          height:159,
      },
      headerBox:{
        marginBottom:30,
        marginLeft:33,
    },
    headerTitle:{
      fontFamily: 'KelvinSlab-Bold',
      fontSize: 48,
      color:theme.palette.titleColor,
      borderLeft: 4,
      borderLeftStyle: 'solid',
      borderLeftColor: "#00bdcd",
      paddingLeft:20,
      lineHeight:1
    },
    textColor:{
        color: '#231f20',
    },
}))




export default WorkArea;