import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline'


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CustomThemeProvider from './theme/CustomThemeProvider';


import registerServiceWorker from './registerServiceWorker';


const app = //<React.StrictMode>
<BrowserRouter>
<CustomThemeProvider>
<CssBaseline />
    
      <App />
    </CustomThemeProvider>
    </BrowserRouter>
    //</React.StrictMode>

ReactDOM.render(app, document.getElementById('root')
);
registerServiceWorker();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
