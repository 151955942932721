import React, {useState, useContext} from 'react';
import {CustomThemeContext} from '../../theme/CustomThemeProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, Typography, } from '@material-ui/core';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import IconButton from '@material-ui/core/IconButton';

import {} from './Home.css';
//import tileData from './tileData';
import Banner from '../../components/Banner/Banner';
import FocusArea from '../FocusArea/FocusArea';
import WorkArea from '../WorkArea/WorkArea';
import Contact from '../../components/Contact/Contact';
import ShareWindow from '../../components/ShareWindow/ShareWindow';
import Error from '../Error/Error';

import sonylogo from '../../assets/Images/sonylogo.png';
import FOX_wordmark from '../../assets/Images/FOX_wordmark.png';
import chapmanleonard from '../../assets/Images/chapmanleonard.png';
import impactograph_logo from '../../assets/Images/impactograph_logo.png';






const collaborationContent = [
    {
        img:sonylogo,
        title:'sonypic',

    },
    {
        img:FOX_wordmark,
        title:'FOX_wordmark',
    },
    {
        img:chapmanleonard,
        title:'chapmanleonard',
    },
    {
        img:impactograph_logo,
        title:'impactograph',
    }

]

const Home = props => {
    
    const { currentTheme } = useContext(CustomThemeContext)
    const classes = useStyles();
    const [shareOpen, setShareOpen] = useState(false);
    const [shareDetails, setShareDetails] = useState({img:"", title:"" });
    const [shareURL, setShareURL] = useState(window.location);
    const [showError, setShowError] = useState({type:false, msg:""});

    //let linkurl = window.location;
    let linkTitle = "";

    const openShareWindow = (pTile) =>{
        //linkurl = ;
        setShareURL(`${window.location.origin}/posts/${pTile.slug}`);
        //console.log("opeshare", pTile);
        setShareDetails(pTile)
        setShareOpen(true);
    }

    const closeShareWindow = () => {
        setShareDetails({})
        setShareURL(window.location);
        setShareOpen(false);
    }
    const displayError = (msg) =>{
        setShowError({type: msg !== "" ? true:false , msg:msg  })
    }



    return (
        <React.Fragment>
            {showError.type ? <Error>{showError.msg}</Error> : null}
            <ShareWindow props={props} closeShareWindow={closeShareWindow} shareOpen={shareOpen} shareDetails={shareDetails} linkurl={shareURL} title={linkTitle} />
            <Banner headerFont={props.headerFont} displayError={displayError} currentTheme={currentTheme}/>
            <div>
             <Grid component="div" style={{display:"flex", justifyContent:"center", marginTop:-18, marginBottom:-18}}>
             <IconButton href="#focus-area" aria-label="focus-area" className={classes.downArrow}>
                <KeyboardArrowDownRoundedIcon className={classes.downArrowIcon}/>
            </IconButton>
             </Grid>
            </div>
            {/* focus Container*/}
            <FocusArea {...props} currentTheme={currentTheme}/>
            {/* work container */}
            <WorkArea {...props} currentTheme={currentTheme} openShareWindow={openShareWindow} />
            {/**Contact Area */}
            <Contact props={props} currentTheme={currentTheme}/>
            
            <Container component="div" varient='div' className={['collaborationContainer', classes.collaborationContainer, classes.containerVerticalPadding].join(' ')}>

                <Box className={classes.headerBox} key={"collbrbox"}>
                    <Typography component="h3" varient="h3" className={classes.headerTitle}>Collaborations</Typography>
                </Box>
                <Grid item style={{marginLeft:32}} container maxwidth="lg" xs={9} sm={12} component="div">
                    <ul style={{display:'inline-flex', paddingLeft:'0px', listStyle:'none', alignItems:'center' }}>
                        {collaborationContent.map((tile) => (
                            <li key={Math.random()} style={{paddingLeft:'30px', paddingRight:'30px'}}>
                                <img style={{width:'100%'}} src={tile.img} alt={tile.title} />
                            </li>
                        ))}
                    </ul>
                </Grid>
            </Container>
            <div></div>
            
        </React.Fragment>
    )

}

const useStyles = makeStyles((theme) =>({
    
      downArrow:{
        padding:4,
        backgroundColor:theme.palette.backgroundColor,
        border: "1px solid #a70237", 
        filter:"drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
        '&:hover': {
            backgroundColor: "#F2024E",
            color: "#FFFFFF"
        }
      },
      downArrowIcon:{
        fontSize: 30, 
        color:"#a70237" ,
        '&:hover': {
            color: "#FFFFFF"
        }
      }, 
      
      headerBox:{
          marginBottom:30,
          marginLeft:33,
      },
      headerTitle:{
        fontFamily: 'KelvinSlab-Bold',
        fontSize: 48,
        color:theme.palette.titleColor,
        borderLeft: 4,
        borderLeftStyle: 'solid',
        borderLeftColor: "#00bdcd",
        paddingLeft:20,
        lineHeight: 1
      },
      textColor:{
          color: '#231f20',
      },
      collaborationContainer:{
          backgroundColor:theme.palette.collabrationBackground
      },
      
      containerVerticalPadding:{
        paddingTop:30,
        paddingBottom:30,  
      }

}))



export default Home;