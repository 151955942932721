import React from 'react';
import { Box } from '@material-ui/core';

const Error = props => {
    return (
        <React.Fragment>
            <Box style={{textAlign:"center", paddingTop:10, paddingBottom:10}}>
                {props.children}
            </Box>
        </React.Fragment>
    )
}

export default Error;