import React, { useState, } from 'react';

import './Contact.css';

import { makeStyles, } from '@material-ui/core/styles';
import { Container, Box, Grid, Typography, Button, Paper} from '@material-ui/core';


import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

//import logolarge from '../../assets/Logos/logolarge.png';

import TextField from '../../components/Input/TextField';


const Contact = (props) => {
    const classes = useStyles();

    const [nameBool, setNameBool] = useState(false);
    const [emailBool, setEmailBool] = useState(false);
    const [phoneBool, setPhoneBool] = useState(false);
    const [companyBool, setCompanyBool] = useState(false);
    const [messageBool, setMessageBool] = useState(false);
    const [submitForm, setSubmitForm] = useState(false);

    let isDisabled = true;
    //let submitForm = false;

    const inputClasses = [classes.contactFormLabel];
    const emailClasses = [classes.contactFormLabel];
    const phoneClasses = [classes.contactFormLabel];
    const companyClasses = [classes.contactFormLabel];
    const messageClasses = [classes.contactFormLabel];

    const logoLarge = "assets/Logos/logoLarge.png";
    const logoDark = "assets/Logos/logoDark.svg";


    if(nameBool){
        inputClasses.push(classes.contactFormLabelSelected);
    }
    if(emailBool){
        emailClasses.push(classes.contactFormLabelSelected);
    }
    if(phoneBool){
        phoneClasses.push(classes.contactFormLabelSelected);
    }
    if(companyBool){
        companyClasses.push(classes.contactFormLabelSelected);
    }
    if(messageBool){
        messageClasses.push(classes.contactFormLabelSelected);
    }

    if(nameBool && emailBool && phoneBool && companyBool && messageBool){
        isDisabled = false;
    }
    //console.log("contact", props.currentTheme)
    const submitHandler = () => {
        setSubmitForm(true);
        resetForm();
    }
    const cancelSubmitHandler = () =>{
        console.log("adsd");
        setSubmitForm(false);
    }

    const resetForm = () => {
        document.getElementById("input-name").value = "";
        document.getElementById("input-email").value = "";
        document.getElementById("input-phone").value = "";
        document.getElementById("input-company").value = "";
        document.getElementById("input-message").value = "";
        setNameBool(false);
        setEmailBool(false);
        setPhoneBool(false);
        setCompanyBool(false);
        setMessageBool(false);

        setTimeout(() => {
            
            cancelSubmitHandler()
        }, 3000);
    }
    let submitFormCont = null;
    if(submitForm){
        console.log('came her');
        submitFormCont = <Grid container style={{marginTop:-70, justifyContent:"center"}}>
                        <Paper className={"thankPaper"} style={{backgroundColor:"#006e00", padding:"15px 40px", display:"flex"}}>
                            <CheckCircleOutlinedIcon className={"checkCorrect"} /> 
                            <Typography className="thankText">Thank you, your inquiry has been sent!</Typography>
                        </Paper>
                    </Grid>
    }


    return (
        <Container name="contact" id="contact" area-label="contact" area-hidden="true" component="div" className={[classes.contactArea, "contactArea", classes.containerVerticalPadding].join(' ')} maxWidth="lg" varient="div">
                {submitFormCont}
                
                <Box className={classes.headerBox}>
                    <Typography component="h3" varient="h3" className={classes.headerTitle}>Lets Work Together</Typography>
                    <Typography component="p" varient="p" className={classes.contactHeader}> Have a project in mind? Please don’t hesitate to reach out. I would love to hear you ideas and collaborate. </Typography>
                </Box>
                <Grid container>
                    <Grid item xs={6} sm={6} lg={6}>
                    <form className={classes.form} noValidate>
                        <Grid className="formGrid" container direction='row' justifyContent="space-between"> 
                            <Grid item xs={6} className={classes.inputGrid}>
                                <Grid item className={[classes.iconLabel].join(' ')}>
                                    <PersonOutlinedIcon className={inputClasses.join(" ")}/>
                                    <Typography id="nameLabel" className={inputClasses.join(" ")} style={{marginLeft:5}}><span style={{color:'#a70237'}}>*</span>Full Name</Typography>    
                                </Grid>
                                
                                <TextField id="input-name"
                                 changed={(event) => {event.target.value !== "" ? setNameBool(true): setNameBool(false)}} />
                            </Grid>
                            <Grid item xs={6} className={classes.inputGrid}>
                                <Grid item className={[classes.iconLabel].join(' ')}>
                                    <MailOutlineIcon className={emailClasses.join(" ")} />
                                    <Typography id="emailLabel" className={emailClasses.join(" ")} style={{marginLeft:5}}><span style={{color:'#a70237'}}>*</span>Email</Typography>    
                                </Grid>
                                <TextField id="input-email" 
                                changed={(event) => {event.target.value !== "" ? setEmailBool(true): setEmailBool(false)}} />
                            </Grid>
                        </Grid>
                        <Grid container style={{flexDirection:"row", justifyContent:"space-between"}} > 
                            <Grid item xs={6} lg={6} sm={6} className={classes.inputGrid}>
                                <Grid item className={[classes.iconLabel].join(' ')}>
                                    <PhoneOutlinedIcon className={phoneClasses.join(" ")} />
                                    <Typography className={phoneClasses.join(" ")} style={{marginLeft:5}}><span style={{color:'#a70237'}}>*</span>Phone</Typography>    
                                </Grid>
                                <TextField id="input-phone"
                                changed={(event) => {event.target.value !== "" ? setPhoneBool(true): setPhoneBool(false)}} />
                            </Grid>
                            <Grid item xs={6} className={classes.inputGrid}>
                                <Grid item className={[classes.iconLabel].join(' ')}>
                                    <MailOutlineIcon className={companyClasses.join(" ")} />
                                    <Typography className={companyClasses.join(" ")} style={{marginLeft:5}}>Company(If applicable)</Typography>    
                                </Grid>
                                <TextField id="input-company"
                                changed={(event) => {event.target.value !== "" ? setCompanyBool(true): setCompanyBool(false)}} />
                            </Grid>
                        </Grid>
                        <Grid className={classes.formGrid} container> 
                            <Grid item xs={12} sm={12} lg={12} className={classes.inputGrid}>
                                <Grid item className={[classes.iconLabel].join(' ')}>
                                    <CreateOutlinedIcon className={messageClasses.join(" ")} />
                                    <Typography className={messageClasses.join(" ")} style={{marginLeft:5}}><span style={{color:'#a70237'}}>*</span>Message(Provide some details below)</Typography>    
                                </Grid>
                                <TextField id="input-message" 
                                changed={(event) => {event.target.value !== "" ? setMessageBool(true): setMessageBool(false)}} />
                            </Grid>
                        </Grid>
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            className={"button"} style={{marginTop: 50, backgroundColor: isDisabled ? '#f1d7df' : '#a70237', color: '#FFFFFF'}} disabled={isDisabled} onClick={() => submitHandler()}
                        >
                            Fire Away!
                        </Button>
                    
                    
                    </form>
                    </Grid>
                    <Grid item xs={6}>
                        <img src={props.currentTheme === "light"? logoLarge: logoDark} alt={logoLarge} style={{maxWidth:380, width:'100%', float:'right'}} />
                    </Grid>
                </Grid>
                

            </Container>
    )
}



const useStyles = makeStyles((theme) =>({
    formGrid:{
        display:'flex',
    },
    headerBox:{
        marginBottom:30,
        marginLeft:32,
    },
    headerMargin:{
        marginTop:40,
    },
    contactArea:{
        backgroundColor: theme.palette.backgroundColor
    },
    contactHeader:{
        fontFamily: "URWGeometric-Regular",
        color: theme.palette.textPrimary.default,
        fontSize: 16,
        marginTop:10,
    },
    contactFormLabel:{
        fontFamily: "URWGeometric-SemiBold",
        color: theme.palette.textPrimary.default,
        fontSize: 16,
    },
    contactFormLabelSelected:{
        color: '#a70237',
    },
    iconLabel:{
        display:'flex',
        alignItems: 'center'
    },
    form:{
        marginLeft: 32,
    },
    submit: {
        backgroundColor: '#a70237',
        margin: theme.spacing(3, 0, 2),
    },
    submitSelected: {
        backgroundColor: '#a70237',
    },
    headerTitle:{
        fontFamily: 'KelvinSlab-Bold',
        fontSize: 48,
        color:theme.palette.titleColor,
        borderLeft: 4,
        borderLeftStyle: 'solid',
        borderLeftColor: "#00bdcd",
        paddingLeft:20,
        lineHeight:1
    },
    inputGrid:{
        paddingRight:20,
        marginTop:15,
        marginBottom:15,
    },
}))


export default Contact;