import React, { useState, useEffect, useRef, useCallback} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Link, Divider, List, ListItem, ListItemIcon, ListItemAvatar ,ListItemText, Avatar, Paper,} from '@material-ui/core';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Collapse from '@material-ui/core/Collapse';
import CommentIcon from '@material-ui/icons/Comment';

import GoogleAuth from '../googleAuth';
import InputComment from '../../../components/inputComment/InputComment';


function checkCommentChildren (pArr, pId){
    const sArr = pArr.map((pElement) => {
        pElement.id === pId ? pElement.isOpen === "false" ? 
            pElement.isOpen = "true" : pElement.isOpen = "false" :
            pElement.isOpen = "false";
        /*if(pElement.id === pId ){
            
            if(pElement.isOpen === "false"){
                pElement.isOpen = "true";
            } else if(pElement.isOpen === "true"){
                pElement.isOpen = "false";
            }
        } else {
            pElement.isOpen = "false";
        }*/
        if(pElement.children !== undefined){
          return checkCommentChildren(pElement.children, pId)
        }
        return pElement;
    })
    return sArr;
}


const CommentPost = (props) => {
    const classes = useStyles();
    const [sopen, setOpen] = useState(true);
    const [authorUser, setAuthorUser] = useState([]); // auth users for mentioning with @...
    const [exEditor, setExEditor] = useState(null);
    
    const [comments, setComment] = useState([]);
    const [commentUser, setCommentUser] = useState({})
    const [commentAvatar, setCommentAvatar] = useState("");
    const commentUserRef = useRef(null);
    const [isLogin, setIsLogin] = useState(false);
    
    const postId = props.postId;
    const postSlug = props.postSlug;

    
    useEffect(() => {

        const map = new Map();
        for (const item of comments){
            //map.set(item.isOpen , false);
            if(!map.has(item.user_id)){
              map.set(item.user_id, true);
              setAuthorUser(authorUser => [...authorUser, {text: item.comment_author, value: item.comment_author, url: item.comment_author_url}])
              
            }
          }
          //setCommentPostList(comments);
    }, [comments])

    const getComments = useCallback(async (pslug = "") =>{
        if(postSlug === "") return;
        const postComments = await fetch(`https://csosadesignco.com/wordpress/wp-json/wl/v1/comments/${postSlug}`)
        
        //const postComments = await fetch("https://localhost/carlos/wp-json/wl/v1/comments/sony-pictures")

        .then(data =>  data.json())
        .catch(error => console.log('error', error));
        if(postComments === undefined) return;
        const commentsArr = [...postComments.comments];
        const postCommentsop = optimizeComments(commentsArr);
        setComment(postCommentsop);
    }, [postSlug])
    useEffect(() => {
        getComments();
    }, [getComments])
    

    const optimizeComments = (comments) => {
        const comms = comments.sort((a, b) => parseInt(b.comment_parent) - parseInt(a.comment_parent))
        //console.log(comms)
        const sortComments = (comms) => {
            const temp = [ ...comms ]
            comms.forEach((o, index) => {
                //temp[0].isOpen = "false";
                if (parseInt(temp[0].comment_parent) === 0) return
                let parentIndex = temp.findIndex(o => parseInt(o.id) === parseInt(temp[0].comment_parent))
                temp[parentIndex].children ? temp[parentIndex].children.push(temp[0]) : temp[parentIndex].children = [temp[0]]
                
                temp.shift();
            })
            //console.log(temp);
            return temp;
        }
        const sorted = sortComments(comms)
        //console.log(sorted);
        const commsToRender = sorted.sort((a, b) => { 
            const s2 = new Date(b.date)
            const s1 = new Date(a.date)
            return s1 - s2
        })
        return commsToRender;
    }







    const handleClick = () => {
        setOpen(!sopen);
      };

    const replyPost = (pId) => {
        if(!isLogin) {
            window.location.href=`${window.location.origin}${window.location.pathname}#googleAuth`;
            return
        };
        if(document.getElementById("editor"+pId) !== null){
            //console.log(inputRef.current, pid, document.getElementById("editor"+pid));
            if(exEditor === null){
                document.getElementById("editorcommon").style.display="none";
            }
            checkCommentChildren(comments, pId);
            
            if(exEditor !== null){
                exEditor.style.display = "none";
            }
            setExEditor(document.getElementById("editor"+pId));
            
        }
        
    }
    
    const cancelReplyHandler = (pId = "") => {
        console.log(pId);
        if(pId !== ""){
           checkCommentChildren(comments, pId);
        }
        if(exEditor != null){
            exEditor.style.display = "none";
        }
        setExEditor(null);
        document.getElementById("editorcommon").style.display="flex";
    }

    const insertUser = async(pData) => {
        //console.log("insertUser ", pData);
        //return;
        const registerData = JSON.stringify({username:pData.givenName, email:pData.email, first_name:pData.givenName, last_name:pData.familyName, password:"abc12346"});
        const registerUser = await fetch(`https://csosadesignco.com/wordpress/wp-json/wp/v2/users/`, {
            method:"POST",
            body: registerData, 
            headers: {
                "Content-Type": "application/json",
                //redirect: 'follow'
            }
        })
        .then(data => data.json())
        .catch(error => console.log(error))

        //console.log(registerUser);
        if(registerUser.id > 0){
            setIsLogin(true);
            setCommentUser({author:registerUser.id, author_email:pData.email, author_name:pData.name, avatar:registerUser.avatar_urls["48"]})
            setCommentAvatar(registerUser.avatar_urls["48"]);
            commentUserRef.current.getCommentUser(registerUser.avatar_urls["48"]);
        }
        
        
    }

    const googleLogin = async (pData) =>{
        console.log(pData.email)
        //const getLoginUser = await fetch(`https://localhost/carlos/wp-json/wp/v2/users/?search=${pData.email}`)
        const getLoginUser = await fetch(`https://csosadesignco.com/wordpress/wp-json/wp/v2/users/?search=${pData.email}`)
        .then(data => data.json())
        .catch(error => console.log(error));
        
        //
        console.log("sss ",getLoginUser);
        if(getLoginUser.length === 0){
            insertUser(pData);
            return ;
        }


        if(getLoginUser[0] !== undefined){
            setIsLogin(true);
            //console.log("came here ",getLoginUser[0]);
            setCommentUser({author:getLoginUser[0].id, author_email:pData.email, author_name:getLoginUser[0].name, avatar:getLoginUser[0].avatar_urls["48"]})
            setCommentAvatar(getLoginUser[0].avatar_urls["48"]);
            commentUserRef.current.getCommentUser(getLoginUser[0].avatar_urls["48"]);
        }
        //console.log(pData, " googleLogin ",localStorage.getItem("userProfile").profileObj)
    }


      const insertPostHandler = async (pData) => {
        
        // setCommentPostList([...commentPostData, pData])
        //const cmtData = {author:1, author_email:"saurabh.subham@gmail.com", author_ip:"127.0.0.1", author_name:"nishapixzones", author_url:"", author_agent:"Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:89.0) Gecko/20100101 Firefox/89.0", content:"welldone", date: new Date(), parent:0, post:16, status:"edit", type:"create" };
        //const cmtData = JSON.stringify({"post":59, "author":1, "author_email":"saurabh.subham@gmail.com", "author_name":"nishapixzones", "content": pData.content});
        //setCommentUser(commentUser, [...commentUser, {"content": pData.content, "post":16, "parent": pData.commentId}])
        const tempCommentData = JSON.parse(JSON.stringify(commentUser));
        tempCommentData.content = pData.content;
        tempCommentData.post = postId;
        tempCommentData.parent = pData.commentId;
        
        const cmtData = JSON.stringify(tempCommentData);

        console.log(tempCommentData);
        //return;

        //const cmtData = JSON.stringify({"author":1, "author_email":"saurabh.subham@gmail.com", "author_name":"carlos_admin", "content": pData.content, "post":16, "parent": pData.commentId});
        //console.log(cmtData)
        const commentURL = "https://csosadesignco.com/wordpress/wp-json/wp/v2/comments";
        //const commentURL = "http://localhost/carlos/wp-json/wp/v2/comments";
        
        await fetch( commentURL, 
            {
                method: "POST", 
                body: cmtData, 
                headers: {
                    "Content-Type": "application/json",
                    //redirect: 'follow'
                }
            }
        )
         .then(data => data.json())
         .catch(error => console.log(error))
         cancelReplyHandler();
         getComments();
         //console.log(insertCom);
     }

     const CommentElement = (pData) =>{
         const abc = pData.element.map((commentData, index)=>{
            return (
                <React.Fragment key={Math.random()}>
                    <List component="ul" disablePadding style={{marginLeft:60}}>
                        <ListItem key={commentData.id} id={commentData.id} className={classes.nested} divider style={{paddingBottom:40}}>
                            <ListItemAvatar>
                                <Avatar alt={commentData.comment_author} src={String(commentData.author_avatar_urls["48"])} />
                            </ListItemAvatar>
                            <ListItemText classes={{secondary:classes.postText}} 
                            primary={
                                <React.Fragment>
                                    <Typography className={classes.authorNam} varient="body2" component="span">{commentData.comment_author}</Typography>
                                    <Typography className={classes.authorDate} style={{fontSize:12}} varient="body2" component="span"> . {commentData.comment_date_gmt}</Typography>
                                </React.Fragment>
                            } secondary={ 
                                <React.Fragment>
                                    <Typography component="span" className={classes.commentText} varient="body2" dangerouslySetInnerHTML={{__html: commentData.comment_content}} /> 
                                </React.Fragment>
                            } />
                            <ListItemSecondaryAction style={{position:"relative", marginLeft:90, top:-29}} >
                                <Link component="button" className={classes.replyBtn} onClick={() => replyPost(commentData.id)} style={{marginRight:"5px"}}>Reply</Link>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem key={`editor${commentData.id}`} id={`editor${commentData.id}`} style={{display: commentData.isOpen === "true" ? "flex":"none", paddingLeft:0, paddingRight:0, inset:0}}>
                            <Grid item xs={12} sm={12} lg={12} xl={12} style={{width:"auto", maxWidth:"100%", display:"flex" }}>
                                
                                    <InputComment commentId={commentData.id} commentAvatar={commentAvatar} authorsName={authorUser} cancelCommentItem = {() => cancelReplyHandler(commentData.id)} insertPostItem={insertPostHandler} />
                                </Grid>
                        </ListItem>
                    {commentData.children !== undefined ? <CommentElement key={Math.random()} depth={pData.depth+1} element={commentData.children} /> : null }
                </List>
                </React.Fragment>
                

            )
        })
        return abc
     }

    return (
        
        <Paper style={{marginLeft:20, marginBottom:50}}>
            <Grid container>
                <List style={{width:"100%", paddingBottom:0}}>
                    <List>
                        <ListItem key={"expan"} onClick={handleClick}>
                            <ListItemIcon className={classes.itemIcon} style={{minWidth:40}}>
                                <CommentIcon />
                            </ListItemIcon>
                            <ListItemText classes={{primary:classes.itemTextTitle}} className={classes.itemTextTitle} primary="Comments" />
                        {//sopen ? <ExpandLess /> : <ExpandMore />
                        }
                        </ListItem>
                    </List>
                    <Divider />
                    <Collapse in={sopen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>

                        {comments.map((commentData)=>{
                            return (
                                <React.Fragment key={Math.random()}>
                                <ListItem key={commentData.id} id={commentData.id} className={classes.nested} divider style={{paddingBottom:40}}>
                                    <ListItemAvatar>
                                        <Avatar alt={commentData.comment_author} src={String(commentData.author_avatar_urls["48"])} />
                                            
                                    </ListItemAvatar>
                                    <ListItemText classes={{secondary:classes.postText}} primary={
                                        <React.Fragment>
                                            <Typography className={classes.authorNam} varient="body2" component="span">{commentData.comment_author}</Typography>
                                            <Typography className={classes.authorDate} varient="body2" component="span"> . {commentData.comment_date_gmt}</Typography>
                                        </React.Fragment>
                                    } secondary={
                                        <React.Fragment>
                                            <Typography component="span" className={classes.commentText} varient="body2" dangerouslySetInnerHTML={{__html: commentData.comment_content}} /> 
                                        </React.Fragment>
                                    } />
                                    <ListItemSecondaryAction style={{position:"relative", marginLeft:90, top:-29}} >
                                        <Link component="button" className={classes.replyBtn} onClick={() => replyPost(commentData.id)} style={{marginRight:"5px"}}>Reply</Link>
                                    </ListItemSecondaryAction>
                                    
                                </ListItem>
                                <ListItem key={`editor${commentData.id}`} id={`editor${commentData.id}`} style={{display:commentData.isOpen === "true" ? "flex": "none", paddingLeft:0, paddingRight:0, inset:0}}>
                                    <Grid item xs={12} sm={12} lg={12} xl={12} style={{width:"auto", maxWidth:"100%", display:"flex" }}>
                                            <InputComment commentId={commentData.id} commentAvatar = {commentAvatar} authorsName={authorUser} cancelCommentItem = {() => cancelReplyHandler(commentData.id)} insertPostItem={insertPostHandler} />
                                        </Grid>
                                </ListItem>
                                {commentData.children !== undefined ? <CommentElement key={Math.random()} depth={1} element={commentData.children} /> : null }
                                </React.Fragment>
                            )
                        })}
                        </List>
                    </Collapse>
                    
                    {isLogin ? <Grid id="editorcommon" item xs={12} sm={12} lg={12} xl={12} style={{width:"auto", maxWidth:"100%", display:"flex", border:"1px solid #a70237"}}>
                        <InputComment ref={commentUserRef} commentId={0} commentAvatar = {commentAvatar} authorName={authorUser}  insertPostItem={insertPostHandler} />
                    </Grid> : null}
                    
                    <GoogleAuth updateLogin={googleLogin} props={props}/>
                </List>
                
            </Grid>
            <Grid>

            </Grid>
            <Grid>
                
            </Grid>

        </Paper>
    )
}

const useStyles = makeStyles((theme) =>({
    itemIcon:{
        color: theme.palette.textPrimary.default
    },
    itemTextTitle:{
        fontSize: 24,
        fontFamily:"KelvinSlab-Bold",
        color: theme.palette.textPrimary.main,
        marginTop:0,
        marginBottom:0,
    },
    authorNam:{
        fontFamily:"URWGeometric-SemiBold",
        fontSize:16,
        color: theme.palette.textPrimary.default,
        textTransform: "capitalize",
    },
    authorDate:{
        fontFamily:"URWGeometric-Regular",
        fontSize:14,
        color:"#757575"
    },
    commentText:{
        fontFamily:"URWGeometric-Regular",
        fontSize:16,
        color: theme.palette.textPrimary.default
    },
    replyBtn:{
        fontSize:16,
        fontFamily:"URWGeometric-Regular",
        color: "#a70237",
        cursor:"pointer",
        '&:hover':{
            textDecoration:"none",
            color:"#F2024E"
        }
    }
}))


export default CommentPost;