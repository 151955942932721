import React, {useEffect,useState} from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography, Paper, Divider, IconButton, TextField } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Link from '@material-ui/core/Link';

import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const ShareWindow = props => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    useEffect(() =>{
      //  console.log("sharewindow ", props.shareOpen);
        setOpen(props.shareOpen)
    }, [props.shareOpen])

    //setOpen(props.shareOpen);
      const handleClose = () => {
        setOpen(false);
        props.closeShareWindow();
      };
      const shareFacebook = (e) =>{
          e.preventDefault();
          const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${props.linkurl}&t=${props.shareDetails.title}&quote=`;
          window.open(facebookURL);

      }
      const shareLinkedIn = (e) => {
        e.preventDefault();
        //console.log(props.linkurl);
        const linkedURL = `https://www.linkedin.com/shareArticle/?mini=true&url=${props.linkurl}&title=${props.shareDetails.title}&summary=&source=AddToAny`;
        window.open(linkedURL);
      }
      const copyLink = (event) => {
        event.preventDefault();
        const filePath = document.getElementById("shareLink");
        filePath.select();
        filePath.setSelectionRange(0, 9999);
        //console.log(filePath.value);
        navigator.clipboard.writeText(filePath.value);
        //document.execCommand("copy", false, filePath)
        
      }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Fade in={open}>
                <Paper className={classes.paper}>
                    <Box style={{display:"flex", alignItems:"center", marginBottom:20}}>
                        <ShareOutlinedIcon style={{fontSize:36, color:"#a70237", marginRight:10}} />
                        <Typography variant="body2" style={{fontFamily:"", fontSize:24, color:"#a70237"}}>Share</Typography>
                    </Box>
                    <Divider />
                    <Grid style={{display:"flex", justifyContent:"center", marginTop:20}}>
                        <Grid item sm={8}>
                            <Box style={{marginBottom:20}}>
                                <img style={{width:"100%"}} src={props.shareDetails.featured_image!== undefined ? props.shareDetails.featured_image['large'] : props.shareDetails.img} alt={props.shareDetails.title} />
                            </Box>
                            <Typography variant="body2" className={classes.shareContent}> Share this project with the social community </Typography>
                            <Grid style={{display:"flex", justifyContent:"center", marginTop:20}}>
                                <IconButton className={classes.iconB} onClick={shareFacebook}><FacebookIcon style={{fontSize:44, color:"#a70237"}} /></IconButton>
                                <IconButton className={classes.iconB}  onClick={shareLinkedIn}><LinkedInIcon style={{fontSize:44, color:"#a70237"}}/></IconButton>
                            </Grid>

                            <Typography variant="body2" className={classes.copyLink}>Or copy link below: </Typography>
                            <Grid style={{display:"flex", alignItems:"center"}}>
                            <CssTextField id="shareLink" underlne="none" InputProps={{
                                readOnly: true,
                            }} fullWidth defaultValue={props.linkurl} 
                                style={{fontFamily:"URWGeometric-light"}} />
                            <Link underline="none" href="#" 
                                className={classes.buttons}
                                style={{}} 
                                onClick={copyLink} variant="body2" >{'COPY'}</Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Fade>
      </Modal>
    )

}

const CssTextField = withStyles((theme) =>({
    root: {
      '& .MuiInputBase-input':{
        fontFamily:"URWGeometric-Regular",
        fontSize:14,
        color:"#470015",
        borderBottom:"1px solid #470015",
        backgroundColor:"#f1d6df",
        height:19,
        paddingLeft:10
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled)::before':{
        borderBottom:"none",  
      },
      '& .MuiInput-underline:before': {
        borderBottom:"none",

      },  
      '& .MuiInput-underline:after': {
        borderBottom:"none",
      },
    },
  }))(TextField);


const useStyles = makeStyles((theme) =>({

    modal: {
        display: 'flex',
        justifyContent: 'center',
        overflowY:"scroll",
      },
      paper: {
        backgroundColor: theme.palette.background.sharePaper,
        height:700,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      copyLink:{
        marginTop:20, 
        marginBottom:10, 
        fontSize:14, 
        color:theme.palette.textPrimary.main,//"#470015", 
        fontFamily:"URWGeometric-SemiBold"

      }, 
      shareContent:{
        textAlign:"center", 
        fontFamily:"URWGeometric-Regular", 
        color:theme.palette.textPrimary.shareColor,//"#a70237", 
        fontSize:24, 
        lineHeight:"1.30"
      },
      iconB:{
        backgroundColor:"rgba(167, 2, 55, 0.16)", 
        padding:14, 
        marginRight:10,
        marginLeft:10,
        '&:hover':{
            backgroundColor:"#f2024e"
        }
      },
      buttons:{
        borderBottom:"1px solid #470015", 
        backgroundColor:"#f1d6df", 
        color:"#470015", 
        fontSize:14, 
        fontFamily:"URWGeometric-Bold", 
        paddingTop:6, 
        paddingBottom:6, 
        paddingRight:15,
        '&:hover':{
            color:"#f2024e"
        }
      }

}))

export default ShareWindow;