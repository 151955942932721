import React, { useState, useEffect, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { NavHashLink as SLink } from 'react-router-hash-link';
import { makeStyles,} from '@material-ui/core/styles';
import {CustomThemeContext} from '../../theme/CustomThemeProvider';


import {Container, Button, Link} from '@material-ui/core';

import Brightness5OutlinedIcon from '@material-ui/icons/Brightness5Outlined';
import Brightness4OutlinedIcon from '@material-ui/icons/Brightness4Outlined';

import SearchBar from '../../components/SearchBar/SearchBar';




import './Header.css';
//import logoWhite from '../../assets/Logos/Circlewhite.png';





const Header = props => {
    //const parentprops = props;
    const classes = useStyles();
    
    const { currentTheme, setTheme } = useContext(CustomThemeContext)
    const [scrolled,setScrolled]= useState(false);

    
    const logoWhite = `${window.location.origin}/assets/Logos/Circlewhite.svg`;
    const logoDark = `${window.location.origin}/assets/Logos/Circledark.svg`;


    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
    })
    const handleScroll=() => {
        const offset=window.scrollY;
        if(offset > 0 ){
            setScrolled(true);
        }
        else{
            setScrolled(false);
        }
    }
    

    const headerClasses = ["headerContainer",classes.backgroundColor];
    if(scrolled){
        headerClasses.push("sticky");
    }
    
    const setThemeModeHandler = () => {
        console.log(currentTheme);
        if(currentTheme === "light"){
            setTheme("dark");
            props.setThemeModeHandler("dark");
        } else {
            setTheme("light");
            props.setThemeModeHandler("light");
        }
    }

    return(
        <Container component="header" className={headerClasses.join(" ")}>
            <nav className="nav">
                <div className="navdiv">
                    <Button className={["logoBtn", classes.logoBtn].join(" ")} component={RouterLink} to="/">
                    <img className="logo" alt="Carlos Logo" src={currentTheme === "light"? logoWhite : logoDark} />
                    </Button>
                    
                </div>
                <div className="navdiv1">
                        <ul className="navUl">
                            <li>
                                <SearchBar {...props}/>
                            </li>
                            <li>
                                <Link to="/mywork/all" id="mywork" component={RouterLink} className={[classes.linkColor, "links"].join(" ")} variant="body2" underline="none">PORTFOLIO</Link>
                            </li>
                            <li>
                                <Link to="/about" id="about" component={RouterLink} className={[classes.linkColor, "links"].join(" ")} variant="body2" underline="none">ABOUT</Link>
                            </li>
                            <li>
                                <SLink to="/#contact" 
                                    id="contacts" 
 className={[classes.linkColor, "links"].join(" ")} variant="body2" underline="none">CONTACT</SLink>
                            </li>
                            <li>
                                <Link to="/resume" id="resume" component={RouterLink} className={[classes.linkColor, "links"].join(" ")} variant="body2" underline="none">RESUME</Link>
                            </li>
                            <li>
                                <Link component="button" className={classes.linkColor} underline="none" onClick={setThemeModeHandler}>{currentTheme === "light" ? <Brightness5OutlinedIcon /> : <Brightness4OutlinedIcon />} </Link>
                            </li>
                        </ul>
                       
                </div>
            </nav>
        </Container>
    );

}



const useStyles = makeStyles((theme) =>({
    
    logoBtn:{
        backgroundColor:theme.palette.textPrimary.default,
    },
    backgroundColor:{
        backgroundColor:theme.palette.backgroundColor,
    },
    linkColor:{
        color: theme.palette.textPrimary.default,
        fontSize: 14,
        fontFamily:"KelvinSlab-Bold",
        
    },
}))

export default Header;