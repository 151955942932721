import React from 'react';
//import { Link as RouterLink } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {} from './SubFooter.css';

const Subfooter = props =>{
    const classes = useStyles();
    return (

            <Grid component="div" maxwidth="lg" className={"SubFooterArea"}>
                <Grid item xs={9}>
                    <Typography component="h3" variant="h3" className={"contactHeaderTitle"} >Let's Work Together</Typography>
                    <Typography component="p" variant="body1" className={"contactHeaderContent"} >Got a project in mind? I'd love to hear about it.</Typography>
                </Grid>
                <Grid item xs={3}>
                <Button href="/#contact" variant="contained" size="small" className={classes.contactButton}>
                    CONTACT ME
                </Button>
                </Grid>
            </Grid>
    )
}

const useStyles = makeStyles((theme) =>({
    contactButton: {
        marginLeft:15, 
        backgroundColor: theme.palette.contactButton.buttonBackground,
        padding: "10px 20px",
        fontSize: 14, 
        float:"right",
        border: "1px solid #a70237", 
        color: theme.palette.contactButton.buttonText,
        fontFamily:"URWGeometric-Medium",
        '&:hover': {
            backgroundColor: theme.palette.contactButton.buttonBackgroundHover,
            color: theme.palette.contactButton.buttonTextHover
        }
      },
}))


export default Subfooter;