import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Typography, Grid} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import GoogleLogin from 'react-google-login';

const GoogleAuth = props => {
    const classes = useStyles();
    const googleCon = <FontAwesomeIcon icon={['fab', 'google']} style={{fontSize:"1.75rem", borderRight:"1px solid", paddingRight:10}} />;
    const [isOpen, setIsOpen] = useState(true);

    const googleAuthHandler = (response) =>{
        //console.log("abc ", response);
        localStorage.setItem("userProfile", response.profileObj);
        setIsOpen(false);
        props.updateLogin(response.profileObj);

    }
    
    const googleAuthErrorHandler = (response) => {
        console.log(response);
    }

 return (
     <React.Fragment>
     { !isOpen ? "" : 
    <Grid id={"googleAuth"} name={"googleAuth"} style={{width:"100%", display:"flex", justifyContent:"center", padding:50}}>
        <GoogleLogin
            clientId="290719817417-ougm11v9asrergp3rgdhsqtvojdg5ohq.apps.googleusercontent.com"
            isSignedIn={false}
            render={renderProps => (
                <Button onClick={renderProps.onClick} disabled={renderProps.disabled} className={classes.googleIcon} variant="contained" startIcon={googleCon}>
                <Typography style={{paddingLeft:50, paddingRight:50}}>Sign in with Google</Typography>
            </Button>
            )}
            buttonText="Login"
            onSuccess={googleAuthHandler}
            onFailure={googleAuthErrorHandler}
            cookiePolicy={'single_host_origin'}
        />
    </Grid>
    }
    </React.Fragment>
    
 )
    
}

const useStyles = makeStyles((theme =>({
    googleIcon:{
        color:"#FFF",
        backgroundColor:"#a70237",
        '&:hover':{
            backgroundColor:"#F2024E"
        }
    }
})))



export default GoogleAuth;