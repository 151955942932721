import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

// Dark theme
const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#25000c',    //dark
    },
    secondary: {
      main: '#FFB74D',
    },
    textPrimary: {
        default: "#cfcece",
        main:"#cfcece",
        shareColor:"#cfcece",
        audioTitle:"#cfcece"
    },
    textSecondary:{
      default: "#FFF"
    },
    // contact button styles 
    contactButton:{
      buttonText:"#A70237",
      buttonBackground:"#FFFFFF",
      buttonTextHover:"#FFFFFF",
      buttonBackgroundHover:"#a70237",
    },
    buttonText:"#f1d7df",
    buttonBackground:"#25000c",
    buttonTextHover:"#A70237",
    buttonBackgroundHover:"#FFFFFF",
    background: {
        default: '#25000c',
        paper:'#25000c',
        sharePaper:'#25000c'
    },
    sectionBackgroundColor:"#140007",
    aboutSectionBackgroundColor:"#25000c",
    footerBackgroundColor:"#140007",
    backgroundColor:'#25000c',
    collabrationBackground:"#490118",
    titleColor:"#cfcece",
    titleBar: {
      main: '#555555',
      contrastText: '#ffffff',
    },
    error: {
      main: red.A400,
    },
  },
})

export default theme