import { createTheme } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors';

// Normal or default theme
const theme = createTheme({
    palette: {
      primary: {
        main: '#231f20',
      },
      secondary: {
        main: '#A70237',
      },
      textPrimary: {
        default: "#231f20",
        main:"#470015",
        shareColor:"#A70237",
        audioTitle:"#757575"
      },
      textSecondary:{
        default: "#231f20"
      },
// contact button styles 
      contactButton:{
        buttonText:"#A70237",
        buttonBackground:"#FFFFFF",
        buttonTextHover:"#FFFFFF",
        buttonBackgroundHover:"#a70237",
      },

      buttonText:"#A70237",
      buttonBackground:"#FFFFFF",
      buttonTextHover:"#FFFFFF",
      buttonBackgroundHover:"#a70237",
      error: {
        main: red.A400,
      },
      background: {       //Getting main background changes
        default: '#FFF',
        paper:'#FFFFFF',
        sharePaper:'#fbf5f7'
      },
      sectionBackgroundColor:"#fbf5f7",
      aboutSectionBackgroundColor:"#FFFFFF",
      footerBackgroundColor:"#FFFFFF",
      backgroundColor:'#FFF',
      collabrationBackground:"#f8ebef",
      titleColor:"#470015",
      
      titleBar: {
        main: '#eeeeee',
        contrastText: '#222222',
      },
    },
  })
  
  export default theme;