import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import './TextField.css';



const textField = (props) => {
    //const classes = useStyles();
    return (
        <CssTextField required fullWidth id= {props.id} inputProps={{className:"inputDetails"}} onChange={props.changed} />
    )

}

const CssTextField = withStyles({
    root: {
        
      '& .MuiInput-underline:after': {
        borderBottomColor: '#a70237',
      },
    },
  })(TextField);



export default textField;