import React, { } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, Typography, } from '@material-ui/core';



import Sliders from '../../components/Slider/Sliders';

import {} from './FocusArea.css';


const FocusArea = props =>{
    const classes = useStyles();

    
    return(
        <React.Fragment>
            <Container  component="div" className={["globalPadding", classes.focusArea].join(" ")} style={{marginTop:-120}}>
                <Grid id="focus-area" name="focus-area" item xs={12} sm={12} lg={12} component="div" className="focusArea">
                    <Box className={classes.headerBox} key={Math.random()}>
                    <Typography component="h3" varient="h3" className={classes.headerTitle}>Area of Focus</Typography>
                    </Box> 

                    <Grid id="focusSliderContainer" item style={{marginLeft:22, marginRight:22}}>
                        <Sliders id="caros" class={classes} />
                        
                     </Grid>

                   
                </Grid>
            </Container>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) =>({

    focusHeader:{
        color:"#470015",
        fontFamily: 'KelvinSlab-Bold',
        fontSize: 24,
      },
      focusArea:{
        backgroundColor: theme.palette.sectionBackgroundColor,
        maxWidth:"100%",
      },
      focusContentStyle:{
        fontFamily:"URWGeometric-Regular",
        fontSize: 14,
        marginTop:8,
      },
      gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      },
      
      
      media: {
        width: 236,
        height: 165,
        margin: '0 auto',
        paddingTop: '0', // 16:9
        marginTop:30,
      },
      headerBox:{
        marginBottom:30,
        marginLeft:33,
      },
      headerTitle:{
        fontFamily: 'KelvinSlab-Bold',
        fontSize: 48,
        color:theme.palette.titleColor,
        borderLeft: 4,
        borderLeftStyle: 'solid',
        borderLeftColor: "#00bdcd",
        paddingLeft:20,
        lineHeight:1
      },
      textColor:{
          color: '#231f20',
      },
      iconArrowHolder:{
        padding:0,
        backgroundColor:"#FFFFFF",
        border: "1px solid #a70237", 
        filter:"drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
        '&:hover': {
            backgroundColor: "#a70237",
            color: "#FFFFFF"
        }
      },
      arrowIcon:{
        fontSize: 30, 
        color:"#a70237" ,
        '&:hover': {
            color: "#FFFFFF"
        }
      },

}))



export default FocusArea