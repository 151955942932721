import React, {useEffect, useState, useCallback } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { NavHashLink as SLink } from 'react-router-hash-link';
import {useLocation, } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, ImageListItem, ImageListItemBar, Card, CardActions, Box, Typography, IconButton, Button, Icon, Paper,} from '@material-ui/core';


import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import CommentIcon from '@material-ui/icons/Comment';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import Subfooter from '../SubFooter/SubFooter';
import staticURL from '../../hoc/config';
//import FetchData from '../../hoc/FetchData';

import brandingsvg from '../../assets/Images/Branding-svg.png';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import ShareWindow from '../../components/ShareWindow/ShareWindow';
import CommentPost from './commentPost/CommentPost';
import {} from './PortfolioPage.css';



function PostNextArrow(props) {
    const { className, style, onClick, classes } = props;
    return (
        <div className={className} style={{...style, right:-15, zIndex:1 }} onClick={onClick}>
        <IconButton id="nextPostBtn"  aria-label="next-button" 
            className={[classes.iconArrowHolder].join(" ")} 
        >
            <KeyboardArrowRightRoundedIcon className={classes.arrowIcon} />
        </IconButton>
        </div>
    );
  }
  
  function PostPrevArrow(props) {
    const { className, style, onClick, classes } = props;
    //console.log(props)
    return (
        <div className={className} style={{...style, left:-10, zIndex:1 }} onClick={onClick}>
        <IconButton id="prevPostBtn"  aria-label="prev-button" 
            className={[classes.iconArrowHolder].join(" ")} 
        >
            <KeyboardArrowLeftRoundedIcon className={classes.arrowIcon} />
        </IconButton>
        </div>
    );
  }


const PortfolioPage = props =>{
    
    const classes = useStyles();
    const [shareOpen, setShareOpen] = useState(false);
    const [shareDetails, setShareDetails] = useState({img:"", title:"" });
    
    const [hostPath, setHostPath] = useState("");
    const [postInformation, setPostInformation] = useState({});
    const [liked, setLiked] = useState(false);
    const [postId, setPostId] = useState(-1);
    const [postContent, setPostContent] = useState(""); // Getting post Content.
    const [postLikes, setPostLikes] = useState(0);
    const [postSlug, setPostSlug] = useState("");
    const [commentStatus, setCommentStatus] = useState('close');
    const [prevPostItem, setPrevPostItem] = useState(null);
    const [nextPostItem, setNextPostItem] = useState(null);
    const [relatedPostData, setRelatedPostData] = useState([]);
    
    const location = useLocation();
    let linkurl = window.location;
    let linkTitle = "";

    //console.log(linkurl, location);

    //const staticURL = "https://csosadesignco.com/wordpress/";
    //const staticURL = "https://localhost/carlos/";
    const jsonPath = "wp-json/wl/v1/";
    const postPath = jsonPath+"posts/";
    const likePath = jsonPath+"likes/";
    const viewPath = jsonPath+"views/"

    const pathPart = location.pathname.split("/");
    const navurl = pathPart[pathPart.length-1]
    //console.log("location ", location);

    const storeView = useCallback(async(pId) => {
        const views = await fetch(staticURL+viewPath+pId)
        .then(data => data.json())
       .catch((error) => console.log(error))

       console.log(views);
    }, [viewPath]);

    const getPostData = useCallback(async(urlPath, method="get") =>{
        //console.log((staticURL+postPath+urlPath));
        const postRes = await fetch(staticURL+postPath+urlPath, {method})
        .then(data => data.json())
        .catch((error) => console.log(error))
        //.then(console.log(data));
        //console.log("sab",postRes);
        if(postRes === undefined) return;
        
        setPostInformation(postRes);
        setPostId(postRes.id);
        
        setPostContent(postRes.content);
        //setPostTools(postRes.tools);
        //setPostRoles(postRes.role);
        setPostLikes(postRes.likes);
        setPostSlug(postRes.slug);
        setPrevPostItem(postRes.pagination['prev']);
        setNextPostItem(postRes.pagination['next']);
        setCommentStatus(postRes.comment_status);

        
        const tempRelatedPost = postRes.relatedPost.flat(); 
        setRelatedPostData(tempRelatedPost);
        storeView(postRes.id);
        setHostPath(location.pathname);
    }, [location.pathname, postPath, storeView])



    useEffect(() =>{
        if(hostPath === location.pathname)return;
        getPostData(navurl);
    }, [location, hostPath, navurl, getPostData])
    
    

    
    const openShareWindow = (pTile = null) =>{
        console.log('sharesin');
        if(pTile === null) {
            setShareDetails(postInformation);
            setShareOpen(true);
            return;
        }
         console.log("opeshare", pTile.title);
         setShareDetails(pTile)
         setShareOpen(true);
     }
 
     const closeShareWindow = () => {
         setShareOpen(false);
     }

     



      const bannerItems = relatedPostData.map((portfolioItem) => (
        <ImageListItem key={portfolioItem.title} cols={1} style={{height:"244", padding:0}}>
            <Grid style={{margin:10}}>
                <Card className={["portGridThumb", classes.thumbContainer].join(" ")} style={{margin:0}}>
                    <Grid item className={classes.thumbContainer}>
                        <img id="imgs" src={portfolioItem.featured_image['medium']} alt={portfolioItem.img} style={{width:"100%", height:"100%"}}/>
                        <ImageListItemBar style={{background:"none", bottom:110, right:10  }}
                            actionIcon={
                                <IconButton aria-label={`info about ${portfolioItem.title}`} className={classes.favButton}>
                                <FavoriteBorderIcon className={classes.iconFav} />
                                </IconButton>
                            }
                        />
                    </Grid>
                <CardActions className={classes.thumbContainer} style={{padding:"8px 12px"}}>
                        <IconButton aria-label="Work" className={classes.iconBg}>
                            <WorkOutlineIcon style={{fontSize:'1.25rem', color:'#FFF'}}/>
                        </IconButton>
                        <Typography id="project-title" varient="body2" className={["gridThumbTitle", classes.gridThumbTitle].join(" ")} component="p">{portfolioItem.title}</Typography>
                        <img src={brandingsvg} alt={brandingsvg} style={{width:"11%", marginLeft:'auto'}}/>
                </CardActions>
            </Card>
            
            <Grid container spacing={0} style={{marginTop:2}}>
                <Grid item xs={6}>
                    <Paper className={classes.thumbContainer}>
                        <Box style={{display:'flex', justifyContent:'center'}}>
                            <Button aria-label="Work" className={classes.thumbButtons} onClick={() => openShareWindow(portfolioItem)} >
                                <ShareOutlinedIcon />
                            </Button> 
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper className={classes.thumbContainer} style={{display:"flex"}}>
                    <Grid item xs={6}>
                        <Box style={{display:'flex', justifyContent:"center", alignItems:"center"}}>
                            <SLink href={`/posts/${portfolioItem.slug}#comment`} to={`/posts/${portfolioItem.slug}#comment`} aria-label="Work" className={classes.thumbButtons} style={{padding:5, minWidth:44, marginLeft:0, marginRight:0}}>
                                <CommentIcon/>
                                <Typography varient="body2" className={classes.workThumbTitle} style={{marginTop:-3, color:'#a70237', fontSize:14}} component="p">{portfolioItem.comments}</Typography>
                            </SLink>
                            
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{display:'inline-flex',  alignItems:"center"}}>
                            <Button href={`/posts/${portfolioItem.slug}`} to={`/posts/${portfolioItem.slug}`} component={RouterLink} aria-label="Work" className={classes.thumbButtons} style={{minWidth:44, marginLeft:0, marginRight:0}}>
                                <VisibilityOutlinedIcon/>
                                <Typography varient="body2" className={classes.workThumbTitle} style={{marginTop:-3, color:'#a70237', fontSize:14}} component="p">{portfolioItem.views}</Typography>
                            </Button>
                            
                        </Box>
                    </Grid>
                    </Paper>
                </Grid>
            </Grid>
            </Grid>
        </ImageListItem>
    ))



      let bannerSettings = {
        centerMode:false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <PostPrevArrow classes={classes} />,
        nextArrow: <PostNextArrow classes={classes} />,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
            breakpoint: 1150,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
            }
            },
            {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                initialSlide: 0
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };
    const checkPostSliderInit = (e) =>{
        setTimeout(() => {
            if(document.getElementById("prevPostBtn") !== null){
                document.getElementById("prevPostBtn").style.display = "none";
                document.getElementById("postSlideContainer").getElementsByClassName("slick-prev")[0].style.display = "none";
            }
        }, 5*100);
    }
    const checkPostSlider=(e) =>{
        
        let tempSlidesToShow = 1;
        
        for(let i = 0;i<bannerSettings.responsive.length; i++)
        {
            if(bannerSettings.responsive[i].breakpoint <= window.innerWidth && i>0){
                tempSlidesToShow = bannerSettings.responsive[i-1].settings.slidesToShow;
                break;
            }
        }

        document.getElementById("nextPostBtn").style.display = "block";
        document.getElementById("postSlideContainer").getElementsByClassName("slick-next")[0].style.display = "block";
        document.getElementById("prevPostBtn").style.display = "block";
        document.getElementById("postSlideContainer").getElementsByClassName("slick-prev")[0].style.display = "block";

        if((tempSlidesToShow+e) === bannerItems.length){
            document.getElementById("nextPostBtn").style.display = "none";
            document.getElementById("postSlideContainer").getElementsByClassName("slick-next")[0].style.display = "none";
        }
        if(e === 0){
            document.getElementById("prevPostBtn").style.display = "none";
            document.getElementById("postSlideContainer").getElementsByClassName("slick-prev")[0].style.display = "none";
        }
        
    }
    useEffect(() => {
        checkPostSliderInit(0);    
    });

    const updatePostLikes = async() => {
        //console.log("postId", postId);
        if(liked) return;
        const method="POST";
        const getLike = await fetch(staticURL+likePath+postId , {method})
        .then(data => data.json())
        .catch((error) => console.log(error))
        setPostLikes(prevLike => Number(prevLike) + 1);
        setLiked(true);
        console.log(getLike);
    }

    

    const buttonsArr = [classes.buttons];
    

    return(
        <Container component="div" varient='div' className={["PortfolioPageContainer", classes.container].join(" ")}>
            <Grid container className={classes.postContentHolder} style={{filter: "drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))"}} >
                <ShareWindow props={props} closeShareWindow={closeShareWindow} shareOpen={shareOpen} shareDetails={shareDetails} linkurl={linkurl} title={linkTitle} />
                <Grid item sm={8} lg={10} style={{display:"flex", justifyContent:"space-between"}}>
                    <Box className={classes.headerBox}>
                        <Icon className={classes.headerTitle} style={{width:50, paddingLeft:10}}>
                            <WorkOutlineIcon style={{fontSize:36}} />
                        </Icon>
                        <Typography component="h3" varient="h3" className={classes.headerTitle} style={{borderLeft:0}} dangerouslySetInnerHTML={{__html: postInformation.title}} />
                    </Box>
                    <Box style={{marginRight:"15px", display:"flex"}}>
                        <Button className={classes.buttons} onClick={() => openShareWindow()}>
                            <ShareOutlinedIcon />
                        </Button>
                        <Button disabled={liked ? true : false} className = {buttonsArr.join(" ")} onClick={updatePostLikes}>
                            {liked ? <FavoriteIcon style={{color:'#FFFFFF'}} /> : <FavoriteBorderIcon />}
                        </Button>
                    </Box>
                </Grid>
                <Grid container style={{marginTop:30}}>
                    <Grid item sm={8} lg={10} xl={10}>
                        <Paper style={{marginRight:"20px", marginBottom:70}}>
                            <Grid className={"postContent"} dangerouslySetInnerHTML={{__html: postContent}} />
                        </Paper>
                    </Grid>
                    <Grid item sm={4} lg={2} xl={2}>
                        <Paper style={{padding:15}}>
                            <Grid style={{display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:10, marginBottom:10}}>
                                <Typography className={classes.postTitle}>Project Info</Typography>
                                <Typography className={classes.postText} style={{fontSize:11}}>{postLikes === null ? 0 : postLikes} Likes</Typography>
                            </Grid>
                            <Grid style={{display:"flex", alignItems:"flex-start", marginTop:10, marginBottom:10}}>
                                <Typography className={classes.postTitle}>Category: </Typography>
                                <Typography className={classes.postText} style={{marginLeft:5, marginTop:3}}>{[...new Set(postInformation.categories)].join()}</Typography>
                            </Grid>
                            <Grid style={{display:"flex", alignItems:"center", marginTop:10, marginBottom:10}}>
                                <Typography className={classes.postTitle}>Created:</Typography>
                                <Typography className={classes.postText} style={{marginLeft:5}}>{postInformation.create}</Typography>
                            </Grid>
                            <Grid style={{marginTop:10, marginBottom:5}}>
                                <Typography className={classes.postTitle}>About:</Typography>
                            </Grid>
                            <Grid style={{marginTop:5, marginBottom:10}}>
                                <Typography className={classes.postText}>{postInformation.about}</Typography>
                            </Grid>
                            <Grid style={{marginTop:10, marginBottom:5, display:"table-caption"}}>
                                <Typography className={classes.postTitle}>Challenge:</Typography>
                            </Grid>
                            <Grid style={{marginBottom:10}}>
                                <Typography className={classes.postText}>{postInformation.challenge}</Typography>
                            </Grid>

                            <Grid style={{marginTop:20}}>
                                <Typography className={classes.postTitle}>My Role:</Typography>
                            </Grid>
                            <Grid style={{marginBottom:10}}>
                                <Typography className={classes.postText}>{postInformation.role}</Typography>
                            </Grid>
                            <Grid style={{marginTop:15, marginBottom:5}}>
                                <Typography className={classes.postTitle}>Tools Used:</Typography>
                            </Grid>
                            <Grid>
                                <img src= {postInformation.tools} alt=""/>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid style={{display:"flex", padding:"0 60px 0 60px"}}>
                <Grid item xs={6} lg={6} xl={6} md={6}>
                  {prevPostItem ? <Button href = {prevPostItem.slug} variant="contained" className={classes.postNavigateBtn} startIcon={<ArrowBackIcon />}>
                        Previous
                    </Button> : null}
                </Grid>
                <Grid item xs={6} lg={6} xl={6} md={6}>
                    {nextPostItem ? <Button href = {nextPostItem.slug} variant="contained" className={classes.postNavigateBtn} endIcon={<ArrowForwardIcon />}>
                        Next
                    </Button> : null}
                </Grid>
            </Grid>
            <Grid style={{padding:"60px 60px 30px 60px"}}>
                <Typography className={classes.headerTitle} style={{borderLeft:0, paddingLeft:10}}>More Projects</Typography>
                <Grid id="postSlideContainer" style={{marginTop:20, marginBottom:30}}>
                    <Slider {...bannerSettings} afterChange={(e) => checkPostSlider(e)} >
                        {bannerItems}
                    </Slider>
                </Grid>
             <Container name="comment" id="comment" area-label="comment" style={{padding:0, paddingTop:30}}>
              { commentStatus === "open" ?<CommentPost name="comment" id="comment" props={props} postSlug={postSlug} postId = {postId} /> : <Paper>Comment is not Allowed</Paper>}
            </Container>
            </Grid>
            <Subfooter />
        </Container>
    )

}


const useStyles= makeStyles((theme) =>({
    container:{
        backgroundColor:theme.palette.sectionBackgroundColor
    },
    postContentHolder:{
        backgroundColor:theme.palette.background.default,
        paddingTop:30,
        paddingBottom:30,
        paddingLeft:60,
        paddingRight:60
    },
    headerTitle:{
        fontFamily: 'KelvinSlab-Bold',
        fontSize: 34,
        color:theme.palette.titleColor,
        borderLeft: 4,
        borderLeftStyle: 'solid',
        borderLeftColor: "#00bdcd",
        paddingLeft:20,
        lineHeight: 1
    },
    headerBox:{
        display:'flex'
    },
    postTitle:{
        fontSize:16, 
        fontFamily:"URWGeometric-Bold",
        color: theme.palette.textPrimary.default
    },
    postText:{
        fontSize:14, 
        fontFamily:"URWGeometric-Regular",
        color: theme.palette.textPrimary.default
    },
    linkText:{
        fontSize:14, 
        fontFamily:"URWGeometric-Regular",
        color: "#a70237",
        cursor:"pointer",
        '&:hover':{
            textDecoration:"none",
            color:"#F2024E"
        }
    },
    googleIcon:{
        color:"#FFF",
        backgroundColor:"#a70237",
        '&:hover':{
            backgroundColor:"#F2024E"
        }
    },
    
    itemTextTitle:{
        fontSize:24, 
        fontFamily:"KelvinSlab-Bold", 
        lineHeight:1,
        color: theme.palette.textPrimary.default
    },
    thumbContainer:{
        backgroundColor:theme.palette.backgroundColor
    },
    buttons:{
        backgroudColor:"unset",
        color:'#a70237',
        border:"1px solid", 
        minWidth:0, padding:4, marginLeft:5, marginRight:5,
        '&:hover': {
            backgroundColor: "unset",
            color: "#F2024E"
        },
        '&:disabled':{
            backgroundColor: "#a70237",
        }
    },
    likedButton:{
      //  backgroundColor:"#a70237",
    },
    gridThumbTitle:{
        color:theme.palette.textPrimary.default
    },
    thumbButtons:{
        backgroudColor:'#FFF',
        color:'#a70237', 
        margin:"4px 10px",
        '&:hover': {
            backgroundColor: "unset",//"#F2024E",
            //color: "#FFFFFF"
            color:"#F2024E"
        }
    },
    iconLayout:{},
    iconBg:{
        backgroundColor:"#470015",
        padding:4
    },
    workThumbTitle:{
        fontFamily:"URWGeometric-Regular",
        fontSize: 16,
        marginLeft:5
      },
    favButton:{
        borderRadius:"10%", 
        background:"rgba(255, 255, 255, 0.7)",
        padding: 5,
        marginRight:10,
        marginBottom:-10
      },
    iconFav:{
        fontSize:16, 
        color:"#a70237 !important",
    },
    iconArrowHolder:{
        padding:0,
        backgroundColor:"#FFFFFF",
        border: "1px solid #a70237", 
        filter:"drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
        '&:hover': {
            backgroundColor: "#a70237 !important",
            color: "#FFFFFF"
        }
      },
    arrowIcon:{
        fontSize: 30, 
        color:"#a70237" ,
        '&:hover': {
            color: "#FFFFFF"
        }
      },
      listTitleText:{
          color: theme.palette.textPrimary.default
      },
      postNavigateBtn:{
          width: "100%",
        backgroundColor: theme.palette.buttonBackground,
        padding: "20px 0px",
        fontSize: 14, 
        border: "1px solid #a70237", 
        color: theme.palette.buttonText,
        textTransform: "uppercase", 
        fontFamily:"URWGeometric-Medium",
        '&:hover': {
            backgroundColor: theme.palette.buttonBackgroundHover,
            color: theme.palette.buttonTextHover
        }
      }

}))

export default PortfolioPage;