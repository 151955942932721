import React, { useState, useEffect, useCallback} from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Grid, Typography, Card, CardMedia, CardActions, Button} from '@material-ui/core';


import Link from '@material-ui/core/Link';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import StaticURL from '../../hoc/config';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import IconButton from '@material-ui/core/IconButton';

import {} from './Banner.css';

function BannerNextArrow(props) {
    const { className, styles, onClick, classes } = props;
    return (
        <div className={className} style={{...styles, right:-15, zIndex:1 }} onClick={onClick}>
        <IconButton id="nextBannerBtn"  aria-label="next-button" 
            className={[classes.iconArrowHolder].join(" ")} 
        >
            <KeyboardArrowRightRoundedIcon className={classes.arrowIcon} />
        </IconButton>
        </div>
    );
  }
  
  function BannerPrevArrow(props) {
    const { className, styles, onClick, classes } = props;
    //console.log(props)
    return (
        <div className={className} style={{...styles, left:-10, zIndex:1 }} onClick={onClick}>
        <IconButton id="prevBannerBtn"  aria-label="prev-button" 
            className={[classes.iconArrowHolder].join(" ")} 
        >
            <KeyboardArrowLeftRoundedIcon className={classes.arrowIcon} />
        </IconButton>
        </div>
    );
  }



const Banner = props => {
    const { displayError } = props;
    const classes = useStyles();
    const [projectcover, setProjectcover] = useState([]);
    const staticURL = `${StaticURL}/wp-json/wl/v1`;

    const bannerlogo = "assets/Logos/bannerlogo.svg";
    const bannerlogodark = "assets/Logos/bannerlogodark.svg";

    const getBannerData = useCallback(async() => {
        let fetchURL = `${staticURL}/posts/`;
        const bannerData = await fetch(fetchURL)
        .then(data => data.json())
        .catch(error => {
            displayError(error.error);
                return;
            }
        );
        //console.log('bannder ', bannerData);
        if(bannerData?.information !== ''){
            if(bannerData?.information === undefined){
                displayError("There is some issue with content loading in Banner...");
                return;
            }
            if(bannerData?.information['code'] === 400){
                //setWrongData(bannerData?.information['message']);
                return;
            }
            setProjectcover(bannerData.information);
        }

    }, [staticURL, displayError])
    
    useEffect(() =>{
        getBannerData();
    }, [getBannerData])

    const bannerItems = projectcover.map((tile) => (
        <Grid key={tile.title}>
        <Card className={classes.backBox} style={{marginLeft:8, marginRight:8}}>
            <CardMedia
                component="img"
                height="156"
                width="188"
                image={tile.featured_image['medium']}
                title={tile.title}
                alt={tile.title}
                /> 
            <CardActions style={{justifyContent:"space-between", padding:"0"}}>
                <Link style={{marginLeft:8}} to= {`/posts/${tile.slug}`} component={RouterLink} variant="body1" underline="none" className={["bannerThumbTitle", classes.textColor, classes.links].join(" ")}>
                    See More
                </Link>
                <IconButton to= {`/posts/${tile.slug}`} component={RouterLink} className={classes.iconButtons} >
                    <ArrowForwardIcon className={classes.thumbArrowIcon} style={{fontSize:18}} />
                </IconButton>
            </CardActions>
        </Card>
        </Grid>
    ))





    let bannerSettings = {
        centerMode:false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <BannerPrevArrow classes={classes} />,
        nextArrow: <BannerNextArrow classes={classes} />,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
            breakpoint: 1150,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };
    const checkBannerSliderInit = (e) =>{
        setTimeout(() => {
            console.log("came here");
            if(document.getElementById("prevBannerBtn") !== null){
                document.getElementById("prevBannerBtn").style.display = "none";
                document.getElementById("bannerSlideContainer").getElementsByClassName("slick-prev")[0].style.display = "none";
            }
        }, 5*100);
    }
    const checkBannerSlider=(e) =>{
        
        let tempSlidesToShow = 1;
        
        for(let i = 0;i<bannerSettings.responsive.length; i++)
        {
            if(bannerSettings.responsive[i].breakpoint <= window.innerWidth && i>0){
                tempSlidesToShow = bannerSettings.responsive[i-1].settings.slidesToShow;
                //console.log(tempSlidesToShow);
                break;
            }
        }

        document.getElementById("nextBannerBtn").style.display = "block";
        document.getElementById("bannerSlideContainer").getElementsByClassName("slick-next")[0].style.display = "block";
        document.getElementById("prevBannerBtn").style.display = "block";
        document.getElementById("bannerSlideContainer").getElementsByClassName("slick-prev")[0].style.display = "block";

        if((tempSlidesToShow+e) === bannerItems.length){
            document.getElementById("nextBannerBtn").style.display = "none";
            document.getElementById("bannerSlideContainer").getElementsByClassName("slick-next")[0].style.display = "none";
        }
        if(e === 0){
            document.getElementById("prevBannerBtn").style.display = "none";
            document.getElementById("bannerSlideContainer").getElementsByClassName("slick-prev")[0].style.display = "none";
        }
        
    }
    const backgroundArr = ["BannerContainer", "bannerBackground"];
    if(props.currentTheme === "dark"){
        backgroundArr.push("bannerBackgroundDark");
    }
    useEffect(() => {
        checkBannerSliderInit(0);    
    });
    return (

            <Container component="div" className={["globalPadding", "BannerContainer1", classes.backContainer].join(" ")} >
                
                <Grid className={backgroundArr.join (" ")} >
                    
                    <Grid item xs={3} lg={3} sm={4} style={{paddingLeft:30, paddingRight:10, paddingTop:20, display:'flex', justifyContent:'center', flexDirection:"column"}}>
                        <img src= {props.currentTheme=== "light"? bannerlogo : bannerlogodark} alt={bannerlogo} />
                        <Typography component="h3" variant="h3" className={"bannerFont"} style={{color:'#00bdcd', fontSize:24}} >Visual Designer</Typography>
                        <Typography component="h3" variant="h3" className={["bannerFont", classes.textColor].join(" ")} style={{fontSize:20}} >Never Stop Learning</Typography>
                        <Box style={{marginTop:20}}>
                            <Button variant="contained" href={"/mywork/all"} commponent={RouterLink} size="small" className={classes.bannerPortfolioButton}>
                                PORTFOLIO
                            </Button>
                            <Button variant="contained" href={"#contact"} size="small" className={classes.bannerLetsButton}>
                                LET'S CREATE
                            </Button>
                        </Box>
                    </Grid>
                    <Grid id="bannerSlideContainer" item xs={9} lg={9} sm={8} style={{paddingTop:428, paddingRight:0, marginLeft:20}}>
                        <div>
                        <Typography className={["bannerFont", "titleProject", classes.textColor].join(" ")} > Recent Projects</Typography>
                        <Grid item xs={12}>
                        <Slider {...bannerSettings} afterChange={(e) => checkBannerSlider(e)} >
                        {bannerItems}
                        </Slider>
                        </Grid>
                        </div>
    </Grid>
                </Grid>
                
            </Container>

    )
}

const useStyles = makeStyles((theme) =>({
    bannerBackground:{
        
        backgroundSize:"632px 436px"
    },
    bannerLetsButton: {
        marginLeft:14, 
        backgroundColor: theme.palette.buttonBackground,
        padding: "7px 15px",
        fontSize: 14, 
        border: "1px solid #a70237", 
        color: theme.palette.buttonText,
        fontFamily:"URWGeometric-Medium",
        '&:hover': {
            backgroundColor: theme.palette.buttonBackgroundHover,
            color: theme.palette.buttonTextHover
        }
      },
      bannerPortfolioButton:{
        backgroundColor:"#a70237",
        padding:"7px 20px",
        fontSize:14,
        border:"1px solid #a70237",
        color: "#FFFFFF", 
        fontFamily: "URWGeometric-Medium",
        '&:hover': {
            backgroundColor: "#f2024e",
            color: "#FFFFFF"
        }
      },
      links:{
        '&:hover':{
            color:"#F2024E"
        }
      },
      iconButtons:{
          padding:10,
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#FFFFFF"
        } 
      },
      bannerThumbTitle:{
        fontFamily:"URWGeometric-Regular",
        fontSize: 16,
        textDecoration:'none',
        color:'#231f20'
      },
      iconArrowHolder:{
        padding:0,
        backgroundColor:"#FFFFFF",
        border: "1px solid #a70237", 
        filter:"drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
        '&:hover': {
            backgroundColor: "#F2024E",
            color: "#FFFFFF"
        }
      },
      arrowIcon:{
        fontSize: 30, 
        color:"#a70237" ,
        '&:hover': {
            color: "#FFFFFF"
        }
      },
      thumbArrowIcon:{
        fontSize: 30, 
        color:"#a70237" ,
        '&:hover': {
            color: "#F2024E"
        }
      },
      textColor:{
        color: theme.palette.textPrimary.default
        },
        backBox:{
            backgroundColor:theme.palette.backgroundColor
        },
      backContainer:{
        backgroundColor: theme.palette.backgroundColor
      }
}))



export default Banner;