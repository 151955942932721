import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Grid, Typography, Card, CardMedia, CardContent, CardActions, Button} from '@material-ui/core';
import { makeStyles ,} from '@material-ui/core/styles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import StaticURL from '../../hoc/config';

import {} from './Sliders.css';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import IconButton from '@material-ui/core/IconButton';


function SampleNextArrow(props) {
    const { className, style, onClick, classes } = props;
    return (
        <div className={className} style={{...style, right:-15, zIndex:1 }} onClick={onClick}>
        <IconButton id="nextFocusBtn"  aria-label="next-button" 
            className={[classes.iconArrowHolder].join(" ")} 
        >
            <KeyboardArrowRightRoundedIcon className={classes.arrowIcon} />
        </IconButton>
        </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick, classes } = props;
    //console.log(props)
    return (
        <div className={className} style={{...style, left:-10, zIndex:1 }} onClick={onClick}>
        <IconButton id="prevFocusBtn"  aria-label="prev-button" 
            className={[classes.iconArrowHolder].join(" ")} 
        >
            <KeyboardArrowLeftRoundedIcon className={classes.arrowIcon} />
        </IconButton>
        </div>
    );
  }

const Sliders = props =>{
    const classes = useStyles();

    const [focusContent, setFocusContent] = useState([])

    const checkSlider=(e) =>{
    //    console.log(e, items.length, window.innerWidth, settings);

        let tempSlidesToShow = 1;
        for(let i = 0;i<settings.responsive.length; i++)
        {
            if(settings.responsive[i].breakpoint <= window.innerWidth && i>0){
                tempSlidesToShow = settings.responsive[i-1].settings.slidesToShow;
                console.log(tempSlidesToShow);
                break;
            }
        }

        document.getElementById("nextFocusBtn").style.display = "block";
        document.getElementById("focusSliderContainer").getElementsByClassName("slick-next")[0].style.display = "block";
        document.getElementById("prevFocusBtn").style.display = "block";
        document.getElementById("focusSliderContainer").getElementsByClassName("slick-prev")[0].style.display = "block";

        if((tempSlidesToShow+e) === items.length){
            console.log("next");
            document.getElementById("nextFocusBtn").style.display = "none";
            document.getElementById("focusSliderContainer").getElementsByClassName("slick-next")[0].style.display = "none";
        }
        if(e === 0){
            console.log("prev");
            document.getElementById("prevFocusBtn").style.display = "none";
            document.getElementById("focusSliderContainer").getElementsByClassName("slick-prev")[0].style.display = "none";
        }
        
    }
    const initSlider= (e) =>{
        setTimeout(()=>{
            if(document.getElementById("prevFocusBtn") !== null){
                document.getElementById("prevFocusBtn").style.display = "none";   
                document.getElementById("focusSliderContainer").getElementsByClassName("slick-prev")[0].style.display = "none";
            }
        }, 500)
        
    }

    let settings = {
        centerMode:false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        prevArrow: <SamplePrevArrow classes={classes} />,
        nextArrow: <SampleNextArrow classes={classes} />,
        responsive: [
            {
                breakpoint: 1350,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: false,
                }
            },
            {
            breakpoint: 1150,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
            }
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
            },
            {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };
    useEffect(() => {
        fetchCategoryData();
    }, [])
    useEffect(() =>{
        initSlider(0);
    });

    const fetchCategoryData = async() =>{
        const fetchCateDetails = await fetch(`${StaticURL}/wp-json/wl/v1/categories`)
        .then(data => data.json())
        .catch(error => console.log(error));

        if(fetchCateDetails !== undefined && fetchCateDetails !== ""){
            setFocusContent(fetchCateDetails);
        } 
    }


    const items = focusContent.map((tile) => (
        <Grid className={"cardGrid"} key={tile.name}>
        <Card className={["focusBox", classes.focusBox].join(" ")} >
            <CardMedia
                className={"media"}
                image={tile.categoryImage[0]}
                title={tile.name}
            >
            </CardMedia>
            <CardContent style={{height:150}}>
                <Typography className={["focusHeader", classes.titleColor].join(" ")} variant="h3" component="h3">
                    {tile.name}
                </Typography>
                <Typography variant="body2" className={["focusContentStyle", classes.textColor].join(" ")} component="p">
                    {tile.description}
                </Typography>
            </CardContent>
            <CardActions className={"cardActionStyle"}>
                <Typography variant="body2" className={["focusContentStyle", classes.textColor, "focusButtonText"].join(" ")} >
                See Projects
                </Typography>
                <Button to={`/mywork/${tile.slug}`} component={RouterLink} size="small" style={{minWidth:14, marginTop:5}} className={["textCol", classes.links].join(" ")}>
                    <ArrowForwardIcon className={classes.iconButtons}/>
                </Button>
            </CardActions>
        </Card>
        </Grid>
        
    ))


    return (
        <Slider {...settings} afterChange={(e) => checkSlider(e)}>
        {items}
        </Slider>
    )
}




const useStyles = makeStyles((theme) =>({
    links:{
        '&:hover':{
            backgroundColor: "#FFFFFF"
        }
    },
    iconArrowHolder:{
        padding:0,
        backgroundColor:"#FFFFFF !important" ,
        border: "1px solid #a70237 !important", 
        filter:"drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))",
        '&:hover': {
            backgroundColor: "#a70237 !important",
            color: "#FFFFFF !important"
        },
        
      },
      arrowIcon:{
        fontSize: 30,
        color:"#a70237 !important" ,
        '&:hover': {
            color: "#F2024E !important"
        }
      },
      iconButtons:{
        color:"#a70237", 
        fontSize:16,
        '&:hover': {
            color: "#F2024E"
        } 
    },
      titleColor:{
          color: theme.palette.textPrimary.default
      },
      textColor:{
          color: theme.palette.textPrimary.default
      },
      focusBox:{
          backgroundColor:theme.palette.backgroundColor
      }

}))

export default Sliders;