import React, {useState} from 'react';
import { Grid, ImageListItem, ImageListItemBar, Typography, Card, CardActions, IconButton} from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import brandingsvg from '../../assets/Images/Branding-svg.png';

import staticURL from '../../hoc/config';


const ITEM_HEIGHT = 35;

const CardThumb = props => {
    //const workclasses = useStyles();
    //let totalcomments = 0;
    const {workclasses, tile, postId} = props; 
    //const workclasses = props.workclasses;
    //const tile = props.tile;
    const [liked, setLiked] = useState(false);
    //const [postLikes, setPostLikes] = useState(0);

    const jsonPath = "wp-json/wl/v1/";
    const likePath = jsonPath+"likes/";

    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openShareWindow = () =>{
        console.log("welocme ", anchorEl);
        if(anchorEl !== null){handleClose()};
        
        props.openShareWindow(tile);
    }
    const openViewWindow = () => {
        console.log("view");
        if(anchorEl !== null){handleClose()};
        props.openViewWindow(tile);
    }
    const openCommentWindow = () => {
        if(anchorEl !== null){handleClose()};
        props.openCommentWindow(tile);
    }

    const options = [
        {
            title:'Views',
            icons: VisibilityIcon,
            func: openViewWindow
        },
        {
            title:'Comments',
            icons: CommentOutlinedIcon,
            func: openCommentWindow
        },
        {
            title:'Share',
            icons: ShareOutlinedIcon,
            func: openShareWindow
        },
      ];
    
    const updatePostLikes = async() => {
        if(liked) return;
        const method="POST";
        const getLike = await fetch(staticURL+likePath+postId , {method})
        .then(data => data.json())
        .catch((error) => console.log(error))
        //setPostLikes(prevLike => Number(prevLike) + 1);
        setLiked(true);
        console.log(getLike);
    }


    const setLargeThumb = (stile) =>{
        props.setLargeImageDetails(stile);
        //document.getElementById("largeImage").src = stile.img;
    }



    return (
        <Card key={tile.title} className={"workGridThumb"}>
            <ImageListItem key={tile.title} cols={tile.cols || 1}>
                <Grid item className={workclasses.paperBackground}>
                <img src={tile.featured_image['medium']} alt={tile.title} className={"workImage"} onClick={() => setLargeThumb(tile)} />
                <ImageListItemBar style={{background:"none", bottom:50, right:0  }}
                    actionIcon={
                        <IconButton aria-label={`info about ${tile.title}`} className={workclasses.favButton} onClick={updatePostLikes}>
                           {liked ? <FavoriteIcon className={workclasses.iconFav} /> : <FavoriteBorderIcon className={workclasses.iconFav} />}
                            {/*<FavoriteBorderIcon className={workclasses.iconFav} />*/}
                        </IconButton>
                    }
                />
                </Grid>
                <CardActions disableSpacing className={workclasses.paperBackground}>
                    <WorkOutlineIcon className={workclasses.iconBg} style={{fontSize:'2rem', color:'#FFF', padding:7}}/>
                    <Typography varient="body2" className={workclasses.workThumbTitle} component="p">{tile.title}</Typography>
                    <img src={brandingsvg} alt={brandingsvg} style={{width:"9%", marginLeft:'auto'}}/>
                    <div>
                    <IconButton className={workclasses.verticalIcon} 
                        id = "basic-button"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="Work" disableRipple style={{padding:0, borderRadius:0}} onClick={(e) => {handleClick(e)}} >
                        <MoreVertIcon className={workclasses.vertIcon}/>
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        //keepMounted
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        getContentAnchorEl={null}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                        transformOrigin={{vertical: 'top', horizontal: 'left'}}
                        PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                        }}
                    >
                        {options.map((option) => (
                        <MenuItem key={option.title} onClick={() => option.func(tile)}>
                            <ListItemIcon style={{minWidth:40, color:"#a70237"}}>
                                < option.icons />
                            </ListItemIcon>
                            <ListItemText primary={option.title}>
                            </ListItemText>   
                        </MenuItem>
                        ))}
                    </Menu>
                    </div>
                </CardActions>
            </ImageListItem>
            
        </Card>
    )
}
export default CardThumb;