import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback} from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw, } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { makeStyles } from '@material-ui/core/styles';
import {Container, Grid, Button, Typography, Avatar} from '@material-ui/core';
import StarBorder from '@material-ui/icons/StarBorder';

import { } from './inputComment.css';

const InputComment = React.memo(forwardRef((props, ref) => {
    const {insertPostItem, commentAvatar, commentId, cancelCommentItem, authorsName} = props;
    
    const [inPutAvatar, setAvatar] = useState(commentAvatar);
    const [editorsState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
       // console.log("ss ", editorState);
    }, [editorsState]);


    useEffect(() => {
        setAvatar(commentAvatar);
    },[commentAvatar])

    useImperativeHandle(ref, () => ({
        getCommentUser(pValue) {
          setAvatar(pValue);
        }    
      }));

    const callChangeHandler = (e) => {
        if(convertToRaw(editorsState.getCurrentContent()).blocks[0].text !== ""){
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }
    const cancelCommentHandler = () => {
        cancelCommentItem();
    }
    const submitCommentHandler = useCallback(() =>{
        //console.log(convertToRaw(editorState.getCurrentContent()).blocks[0].text);
        console.log(stateToHTML(editorsState.getCurrentContent()));
        const insertItem = {img:"", commentId:commentId, author:"abc", publishTime: Date().toString(), content:stateToHTML(editorsState.getCurrentContent())};
        setEditorState(EditorState.createEmpty());
        setIsDisabled(true);
        insertPostItem(insertItem);
    }, [commentId, editorsState, insertPostItem])

    const classess = useStyles();
    return (
        <Container component="div" style={{}} className={classess.container}>
            <Grid style={{marginBottom:20, display:"flex"}}>
                <Grid item style={{marginRight:"16px"}}>
                    <Avatar alt="avatarInput" src={inPutAvatar}>
                        <StarBorder />
                    </Avatar>
                </Grid>
                <Grid item style={{width:"100%"}}>
                </Grid>
            </Grid>
            <Grid style={{marginTop:"-65px"}}>
            <Editor 
                toolbar={{options:['emoji', 'inline','link'],
                    emoji:{className: classess.inputButton },
                    inline: {
                        options: ['italic', 'bold', 'strikethrough'],
                        italic:{className:classess.inputButton,},
                        bold:{className:[classess.inputButton],},
                        strikethrough:{className:classess.inputButton,}
                    },
                    link: {options: ['link'],link: { className: classess.inputButton }}
                }}
                toolbarStyle={{border:"none", borderTop:"1px solid"}} editorState={editorsState}
                toolbarCustomButtons={[
                    <Grid style={{display:"flex", alignItems:"center", position:"absolute", right:"48px"}}>
                        <Typography style={{fontSize:12, fontFamily:"URWGeometric-Regular", marginRight:20, color:"#231f20"}}>@mention user to notify them | Some HTML is OK </Typography>
                        <Button type="button"
                        variant="contained"
                        color="primary"
                        className={["button", classess.button].join(" ")} style={{paddingLeft:24, paddingRight:24, marginRight: 20,  backgroundColor: '#a70237', color: '#FFFFFF', display: commentId !== 0 ? 'block': 'none' }} onClick={cancelCommentHandler} >Cancel</Button>
                    <Button type="button"
                        variant="contained"
                        color="primary"
                        className={["button", classess.button].join(" ")} style={{paddingLeft:24, paddingRight:24, backgroundColor: isDisabled ? '#f1d7df' : '#a70237', color: '#FFFFFF'}} disabled={isDisabled} onClick={submitCommentHandler} >Post</Button>
                    </Grid>]}
                onChange={callChangeHandler}
                onEditorStateChange={setEditorState}
                editorClassName={classess.editiorStyle}
                mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions: authorsName/*[
                      { text: 'APPLE', value: 'apple', url: 'apple' },
                      { text: 'BANANA', value: 'banana', url: 'banana' },
                      { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                      { text: 'DURIAN', value: 'durian', url: 'durian' },
                      { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                      { text: 'FIG', value: 'fig', url: 'fig' },
                      { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                      { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                    ]*/,
                  }}
            />
            </Grid>
        </Container>
    )


}, () => true))
const useStyles = makeStyles(()=>({
    inputButton:{
        border:"none",
    }, 
    button:{

    },
    container:{
        width:"100%", 
        maxWidth:"100%", 
        paddingLeft:16, 
        paddingBottom:5, 
        paddingTop:14, 
        paddingRight:16
    },
    editiorStyle:{
        fontFamily:"URWGeometric-Regular", fontSize:14
    }
}))

export default InputComment;
